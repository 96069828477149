import { Button, Container } from "@material-ui/core";
import clsx from "clsx";
import NavbarPage from "Components/Page";
import { GetAllProductStyleQueryVariables, GetAllProductStylesIdsQueryVariables, GetPartialProductStyleDocument, GetPartialProductStyleQuery, GetPartialProductStyleQueryVariables, GetProductStyleIdsQuery, GetProductStyleIdsQueryVariables, useGetAllProductStyleQuery, useGetAllProductStylesIdsQuery, useGetProductStyleIdsQuery, useUpdateProductPricesMutation } from "generated/graphql";
import { useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { resetPricePage, selectPricingProductType, selectPricingViewFilter } from "Redux/productPricingReducer";
import { store } from "Redux/store";
import { bToF_Prices, fToB_UpdateProducts } from "./Conversions";
import ProductPricingRow from "./ProductPricingRow";
import ProductTypeSelector from "./SimpleEditors/ProductTypeSelector";
import ViewModeSelector from "./SimpleEditors/ViewModeSelector";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
//import { GetServiceProductOfferingIdsQueryVariables } from "generated/graphql"

export default function ProductPricingPage() {
    //const { data } = useGetAllProductStyleQuery({ variables: { ...filters }, fetchPolicy:'network-only' })
    
    const productTypeId = useAppSelector(selectPricingProductType)
    //const [filters, setFilters] = useState<GetServiceProductOfferingIdsQueryVariables>({ isDeleted: false })
    const [filters, setFilters] = useState<GetAllProductStylesIdsQueryVariables>({ isDeleted: false })
    console.log(filters)

    const { data } = useGetAllProductStylesIdsQuery({ variables: { productTypeId, isDeleted: filters.isDeleted } })
    const renderRow = data?.allProductStylesIds ?? []

    const [scrollHeight, setScrollHeight] = useState<number>(0);
  
    function handleScroll(e: React.UIEvent<HTMLDivElement, UIEvent>) {
        if (e.currentTarget.scrollHeight !== scrollHeight) {
            setScrollHeight(e.currentTarget.scrollHeight)
            e.currentTarget.scrollTop = 0
        }
    }
    function toggleDeletedStatus() {
        setFilters({ ...filters, isDeleted: !(filters.isDeleted ?? true) })
    }

    const viewFilter = useAppSelector(selectPricingViewFilter)

    return (
        <NavbarPage title="Product Pricing" style={{ overflow: "hidden", display: "flex" }}>
            <Container className="fill-width flex-row-center" maxWidth="lg">
                <div className="flex-column" style={{ alignItems: "center" }}>
                   
                    <div className="flex-row fill-width padding-bottom-xsm">
                        <Button
                            variant="contained"
                            className="margin-side-xsm"
                            onClick={toggleDeletedStatus}>
                            {(filters.isDeleted ?? false) ? "Hide Trash" : "Show Trash"}
                        </Button>
                        
                        <div className='flex-grow' />
                        <ProductTypeSelector />
                        <div className="w-2r" />
                        <ViewModeSelector />
                        <div className='flex-grow' />
                        <PricingSaveChangesButton />
                    </div>
                    <div className={clsx(
                        "table-fixed-new",
                        ...viewFilter.map((showCol, index) => showCol ? '' : `hide-column-${index + 1}`)
                    )} style={{ height: "calc(100vh - 14rem)" }} onScroll={handleScroll}>
                        <table style={{ margin: "0 auto" }}>
                            <thead>
                                <tr>
                                    <td align="center" className="h-3r">Style</td>
                                    <td align="center">Cost</td>
                                    <td />
                                    <td align="center">Factor</td>
                                    <td />
                                    <td align="center">Shipping</td>
                                    <td />
                                    <td align="center">Price</td>
                                    <td align="center">%</td>
                                    <td align="center">Promo</td>
                                    <td align="center">%</td>
                                    <td align="center">From Date</td>
                                    <td align="center">To Date</td>
                                    <td align="center">Delete Promo</td>
                                    <td align="center">Clear Changes?</td>
                                </tr>
                            </thead>
                            <tbody>
                                {renderRow.map(renderPricingRow)}
                            </tbody>
                        </table>
                    </div>
                </div>
                <AddNewPriceButton/>
            </Container>
        </NavbarPage>
    )
}

const renderPricingRow = (styleId: number) => (
    <ProductPricingRow
        key={`pricing-row-${styleId}`}
        styleId={styleId}
        
    />
);

function PricingSaveChangesButton() {
    const dispatch = useAppDispatch()

    const [updateProductPrices] = useUpdateProductPricesMutation({
        update(cache, results) {
            results.data?.updateProductPrices.forEach(
                updated => {
                    const data = cache.readQuery<GetPartialProductStyleQuery, GetPartialProductStyleQueryVariables>({
                        query: GetPartialProductStyleDocument,
                        variables: { styleId: updated.styleId }
                    })

                    if (data !== null) {

                        cache.writeQuery<GetPartialProductStyleQuery, GetPartialProductStyleQueryVariables>({
                            query: GetPartialProductStyleDocument,
                            variables: { styleId: updated.styleId },
                            data: {
                                partialProductStyle:
                                {
                                    ...data.partialProductStyle,
                                    ...bToF_Prices(updated.updatedFactor, updated.updatedShipping, updated.updatedPromo ?? undefined)
                                }
                            }
                        })
                    }
                }
            )
            dispatch(resetPricePage())
        }
    })

    function TrySubmit() {
        const { changedFactors, changedShipping, changedPromoPrices, changedStartDate, changedEndDate } = store.getState().productPricing

        try {
            var products = fToB_UpdateProducts(changedFactors, changedShipping, changedPromoPrices, changedStartDate, changedEndDate)
            updateProductPrices({
                variables: {
                    products,
                    interpretCarpetShippingInSqyd: true
                }
            })
        } catch(e){
            window.alert(e);
        }
    }

    return (
        <Button onClick={TrySubmit} variant="contained" color="secondary">Submit Changes</Button>
    )
}

function AddNewPriceButton() {
    const [open, setOpen] = useState(false)
        ;
    const history = useHistory();
    const { path } = useRouteMatch();

    

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleRedirect = (isHardSurface: boolean) => {
        if (isHardSurface) {
            history.push(path.replace("product-pricing", "add-hard-surface"));
        } else {
            history.push(path.replace("product-pricing", "add-soft-surface"));
        }
        handleClose();
    };

    return (
        <div>
            <div style={{
                position: "fixed",
                bottom: 0,
                left: 0,
                right: 0,
                padding: "1rem",
                backgroundColor: "#fff",
                zIndex: 1000,
                boxShadow: "0 -2px 5px rgba(0, 0, 0, 0.1)",
                display: "flex",
                justifyContent: "center"
            }}>
                <Button
                    variant="contained"
                    className="margin-side-xsm"
                    onClick={handleOpen}
                    style={{
                        padding: "0.5rem 2rem",
                        height: "3rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#e0e0e0",
                        color: "#000",
                        fontSize: "1rem"
                    }}
                >
                    Add New Product Pricing
                </Button>
            </div>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Select Surface Type</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please select the type of surface you would like to add.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => handleRedirect(true)}
                        variant="contained"
                        style={{
                            padding: "0.5rem 2rem",
                            height: "3rem",
                            backgroundColor: "#e0e0e0",
                            color: "#000",
                            fontSize: "1rem"
                        }}
                    >
                        Hard Surface
                    </Button>
                    <Button
                        onClick={() => handleRedirect(false)}
                        variant="contained"
                        style={{
                            padding: "0.5rem 2rem",
                            height: "3rem",
                            backgroundColor: "#e0e0e0",
                            color: "#000",
                            fontSize: "1rem"
                        }}
                    >
                        Soft Surface
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}