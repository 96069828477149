import FlatEditButton from "FlatComponents/Button/FlatEditButton";
import FlatSection from "FlatComponents/Layout/FlatSection";
import { Customer, namedOperations, Salesperson, useUpdateCustomerDetailsMutation } from "generated/graphql";
import { prepareCustomer } from "Globals/dataPreparationUtils";
import { isNotNullOrUndefined } from "Globals/GenericValidators";
import { InvoiceCustomerInformationTable } from "Pages/Admin/ProjectManagement/Invoice/InvoiceDocumentHeader";
import UpdateCustomerDetailDialog from "Pages/Admin/ProjectManagement/Invoice/UpdateCustomerDetailDialog";
import { useState } from "react";
import { useAppSelector } from "Redux/hooks";
import { selectContractSigningDate, selectSAHNumber } from "Redux/jobTrackerReducer";

interface JobTrackerCustomerInformationProps {
    projectCoordinator: string;
    customer: Customer | undefined;
    salespeople: Salesperson[] | undefined;
}

export default function JobTrackerCustomerInformation({
    projectCoordinator,
    customer,
    salespeople
}: JobTrackerCustomerInformationProps) {
    const [editCustomerOpen, setEditCustomerOpen] = useState(false);
    const [updateCustomerDetails] = useUpdateCustomerDetailsMutation({
        refetchQueries: [namedOperations.Query.GetJobInvoiceData],
        awaitRefetchQueries: true,
        onError: () => alert("Could not update customer details")
    });

    const sahNum = useAppSelector(selectSAHNumber);
    const sahElement = (
        <p className="margin-none">
            [{sahNum}]
        </p>
    );
    const contractDate = useAppSelector(selectContractSigningDate)

    return (
        <FlatSection
            header={`Project Coordinator - ${projectCoordinator}`}
            centerAdornment={sahElement}
            collapsible
            darkHeader
            removePadding
        >
            <div id="edit-customer-bar" className="flex-gap-xsm">
                <p className="margin-none flat-font-md">
                    Customer Information
                </p>
                <FlatEditButton onClick={() => setEditCustomerOpen(true)}/>
            </div>

            <div className="flat-font" style={{padding: "var(--flat-inner-container-radius)"}}>
                {isNotNullOrUndefined(customer) && (
                    <InvoiceCustomerInformationTable
                        {...customer!}
                        date={contractDate}
                        salesperson1={salespeople?.[0]}
                        salesperson2={salespeople?.[1]}
                        renderExpanded
                        onEdit={() => setEditCustomerOpen(true)}
                    />
                )}
            </div>

            {/* must wait to actually render this rather than just use the open flag because customer will be undefined when this renders */}
            {editCustomerOpen && (
                <UpdateCustomerDetailDialog
                    open={true}
                    defaultCustomer={customer!}
                    onCancel={() => setEditCustomerOpen(false)}
                    onSubmit={(newCustomer: Customer) => {
                        updateCustomerDetails({ variables: { updatedCustomer: prepareCustomer(newCustomer) } }).then(() => {
                            setEditCustomerOpen(false)
                        });
                    }}
                />
            )}
        </FlatSection>
    )
}