import Typography from "@material-ui/core/Typography";
import FlatSection from "FlatComponents/Layout/FlatSection";
import { BreakdownTableProps, makeMaterialBreakdownRows } from "./BreakdownTableUtils";
import { formatAsIntOrNDecimals } from "Globals/StringFormatting";

export default function MaterialBreakdownTable({ title, areaBreakdowns }: BreakdownTableProps) {

    const breakdownRows = makeMaterialBreakdownRows(areaBreakdowns);
   
        if (breakdownRows.length <= 0)
        return <Typography>No area or product information to show</Typography>;

    return (
        <FlatSection centerAdornment={title ?? "Materials"} className="flat-job-detail-table" removePadding={true}>
            <table>
                <thead>
                    <tr>
                        <td>Line</td>
                        <td>Type</td>
                        <td>Amount</td>
                        <td>Style</td>
                        <td>Color</td>
                        <td>Area</td>
                    </tr>
                </thead>
                <tbody>
                    { breakdownRows.map((row, index) => (
                        <tr key={index}>
                            <td>{row.line}</td>
                            <td>{row.productType}</td>
                            <td>{formatAsIntOrNDecimals(row.amount)} {row.amountUnit}</td>
                            <td>{row.style}</td>
                            <td>{row.color}</td>
                            <td>{row.area}</td>
                        </tr>
                    )) }
                </tbody>
            </table>
        </FlatSection>
    );
}
