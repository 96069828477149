import { Button, Container, Modal, Typography } from "@material-ui/core";
import clsx from "clsx";
import FileUploadButton from "Components/Forms/Controls/FileUploadButton";
import NavbarPage from "Components/Page";
import Section from "Components/PageLayout/Section";
import { BulkAddNewCarpetColorsMutationVariables, ColorNamePairInput, useBulkAddNewCarpetColorsMutation } from "generated/graphql";
import { isEmptyString, isPositiveNumber } from "Globals/GenericValidators";
import { ChangeEvent, useEffect, useState } from "react";
import { resetAddProduct, selectIsNewColorMode, selectIsStyleUnselected, selectIsVendorFilledOut, selectNSEMode, setColor, setTypeId, setVendorColor } from "Redux/addProductReducer";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { store } from "Redux/store";
import * as XLSX from 'xlsx';
import { v_fToBVendorSourcedProduct } from "./Conversions";
import CreateNewStyle from "./CreateNewStyle";
import SoftColorEditor from "./SimpleEditors/SoftColorEditor";
import StyleEditor from "./SimpleEditors/StyleEditor";
import UploadMode from "./SimpleEditors/UploadModeEditor";
import VendorColorEditor from "./SimpleEditors/VendorColorEditor";
import VendorEditor from "./SimpleEditors/VendorEditor";
import VendorOrderSizeEditor from "./SimpleEditors/VendorOrderSizeEditor";
import VendorStyleEditor from "./SimpleEditors/VendorStyleEditor";
import { useHistory, useRouteMatch } from "react-router";

type AddButtonScenario = "None" | "Add New Product"

export default function AddSoftProductPage() {
    const editorMode = useAppSelector(selectNSEMode)
    const dispatch = useAppDispatch()
    const history = useHistory();
    const { path }=useRouteMatch();

    const [bulkUpload] = useBulkAddNewCarpetColorsMutation({
        onCompleted() { window.alert("Upload Complete"); },
        onError() { window.alert("Server rejected upload"); }
    })

    useEffect(
        () => {
            dispatch(resetAddProduct())
            dispatch(setTypeId(4))
        },
        [dispatch]
    )

    function submitNewProduct() {
        const addProductData = store.getState().addProduct

        const styleId = addProductData.styleId
        const colorName = addProductData.color.value

        const vendor = v_fToBVendorSourcedProduct(
            addProductData.vendorId,
            addProductData.vendorStyle,
            addProductData.vendorColor,
            addProductData.vendorOrderSize,
            "0",
            "0")

        if (isEmptyString(colorName)) throw new Error("Color name is blank")
        if (styleId < 1) throw new Error("Select a style")

        bulkUpload({
            variables: {
                styleId,
                vendorId: vendor.vendorId,
                vendorStyle: vendor.style,
                orderSize: vendor.orderQuantity,
                pairs: [{ vendorColor: vendor.color, privateColor: colorName }]
            }
        })

        dispatch(setColor({ id: -1, value: "" }))
        dispatch(setVendorColor(""))
    }

    function preventUpload() {
        const addProduct = store.getState().addProduct
        if (addProduct.styleId < 1) {
            window.alert("Please select a style");
            return true;
        }
        else if (addProduct.vendorId < 1) {
            window.alert("Please select a vendor");
            return true;
        }
        else if (!isPositiveNumber(addProduct.vendorOrderSize)) {
            window.alert("Please set an order size");
            return true;
        }
        else if (isEmptyString(addProduct.vendorStyle)) {
            window.alert("Please set a vendor style");
            return true;
        }
        else return false;
    }

    function convertData(data: string): BulkAddNewCarpetColorsMutationVariables["pairs"] {
        var lineSplitArray = data.split("\n").map(d => d.split(","));

        // var entry = lineSplitArray.splice(0, 1)[0]
        // if (entry[0] !== "Vendor ID (Number)" || !isPositiveNumber(entry[1])) throw "Vendor ID line is invalid"
        // const vendorId = +entry[1]

        // entry = lineSplitArray.splice(0, 1)[0]
        // if (entry[0] !== "Vendor Style" || isEmptyString(entry[1])) throw "Vendor Style line is invalid"
        // const vendorStyle = entry[1]

        // entry = lineSplitArray.splice(0, 1)[0]
        // if (entry[0] !== "Roll Length" || !isPositiveNumber(entry[1])) throw "Roll length line is invalid"
        // const orderSize = +entry[1]

        // lineSplitArray.splice(0, 1)//Skip Empty line
        var entry = lineSplitArray.splice(0, 1)[0]
        if (entry[0] !== "Vendor Color" || entry[1] !== "Private Label Color") throw new Error("Column category names are invalid")

        //Only entries remaining in lineSplitArray are vendor color / label pairs
        lineSplitArray = lineSplitArray.filter(e => e.length === 2 && e[0] !== "" && e[1] !== "") //Filters out the blanks

        const pairs: ColorNamePairInput[] = lineSplitArray.map(e => {
            return {
                privateColor: e[1],
                vendorColor: e[0]
            }
        })


        return pairs
    }

    function uploadValues(e: ChangeEvent<HTMLInputElement>) {
        if ((e.target.files?.length ?? 0) > 0) {
            const file = e.target.files![0]
            const reader = new FileReader();
            reader.onload = (evt) => {
                //Parse data
                if (evt.target === null) return;
                const bstr = evt.target.result;
                const wb = XLSX.read(bstr, { type: 'binary' });

                //grab first worksheet
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];

                //Pull data from ws
                const data = XLSX.utils.sheet_to_csv(ws);

                try {
                    const pairs = convertData(data)
                    const addProduct = store.getState().addProduct
                    bulkUpload({
                        variables: {
                            styleId: addProduct.styleId,
                            vendorId: addProduct.vendorId,
                            vendorStyle: addProduct.vendorStyle,
                            orderSize: +addProduct.vendorOrderSize,
                            pairs
                        }
                    })
                }
                catch (e) {
                    window.alert(e)
                }
            };

            reader.readAsBinaryString(file);
        }

        //Prompt for file upload
    }

    const [isBulk, setIsBulk] = useState<boolean>(false)

    return (
        <NavbarPage title="Add Soft Surface Product">
            <div className="fill-width flex-column padding-side-lg">
                <Section title="Add A Style">
                    <div className="fill-width flex-row flex-gap-md">
                        <VendorEditor />
                        <VendorStyleEditor />
                        <StyleEditor noEdit />
                        <VendorOrderSizeEditor />
                    </div>
                </Section>
                <Section title="Add Color(s)">
                    <div className="fill-width flex-row flex-gap-md">
                        <UploadMode value={isBulk} setValue={setIsBulk} />

                        {isBulk ? (<>
                            <div className="flex-column-center">
                                <Typography>
                                    Need the template?
                                </Typography>
                                <a href="/files/Carpet_Color_Upload_Template.xlsx" download>
                                    Click to download
                                </a>
                            </div>
                            <FileUploadButton
                                color="secondary"
                                preventUpload={preventUpload}
                                onUploadEvent={uploadValues}
                                accept=".csv,.xlsx,.xls"
                                variant="contained">
                                Upload and Submit
                            </FileUploadButton>
                        </>) : (<>
                            <VendorColorEditor />
                            <SoftColorEditor />
                            <AddProductButton onClick={submitNewProduct} />
                        </>)}
                    </div>
                </Section>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => history.push(path.replace("add-soft-surface", "product-pricing"))}
                >
                    Back to Product Pricing
                    </Button>
                </div>
            </div>
            <Modal open={editorMode !== "None"}>
                <div className="fill-height flex-row flex-centered" style={{ justifyContent: "space-around" }}>
                    <Container maxWidth="md" className="modal-content padding-md">
                        <CreateNewStyle isForHardSurface={false} />
                    </Container>
                </div>
            </Modal>
        </NavbarPage>
    )
}

function AddProductButton({ onClick }: { onClick: (scenario: AddButtonScenario) => void }) {
    const isNewColor = useAppSelector(selectIsNewColorMode)
    const isVendorFilledOut = useAppSelector(selectIsVendorFilledOut)
    const styleIsUnselected = useAppSelector(selectIsStyleUnselected)


    function determineScenario(): AddButtonScenario {
        if (!styleIsUnselected && isVendorFilledOut) {
            if (isNewColor) return "Add New Product"
        }
        return "None"
    }

    const scenario: AddButtonScenario = determineScenario()

    return (
        <div className='flex-column-center'>
            <Button
                className={clsx({ 'visibility-hidden': scenario === "None" })}
                variant="contained"
                onClick={() => onClick(scenario)}>
                Add New Product
            </Button>
        </div>
    )
}