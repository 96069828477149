import { ApolloCache } from "@apollo/client";
import { Button, IconButton, Tooltip } from "@material-ui/core";
import { Launch } from "@material-ui/icons";
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';
import { GetServiceLaborRuleDocument, GetServiceLaborRuleQuery, GetServiceLaborRuleQueryVariables, namedOperations, UpdateServiceProductOfferingMutation, useGetServiceLaborRuleQuery, useTryRemoveLaborRuleMutation, useUpdateServiceProductOfferingMutation } from "generated/graphql";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { changeCost, changeMinimum, changePrice, resetCost, resetMinimum, resetPrice, selectCost, selectIsCostDifferent, selectIsMinimumDifferent, selectIsPriceDifferent, selectMinimum, selectPrice } from "Redux/laborReducer";
import { MinimumAmountFormat } from "./MinimumAmountFormat";
import { MoneyNumberFormat } from "./MoneyNumberFormat";
import ProductsForServiceDialog from "./ProductsForServiceDialog";

type ReturnedLaborRule = GetServiceLaborRuleQuery["serviceLaborRule"]
interface UpdateLaborRulePostMutationProps {
    cache: ApolloCache<UpdateServiceProductOfferingMutation>,
    id: number,
    updateFunction: ((laborRule: ReturnedLaborRule) => ReturnedLaborRule)
}

function updateLaborRulePostMutation({ cache, id, updateFunction }: UpdateLaborRulePostMutationProps) {

    console.log("update post mutation called", id, updateFunction)

    const { serviceLaborRule } = cache.readQuery<GetServiceLaborRuleQuery, GetServiceLaborRuleQueryVariables>(
        { query: GetServiceLaborRuleDocument, variables: { id } }
    )!

    console.log("existing lr", serviceLaborRule)

    function overwriteRule(rule: ReturnedLaborRule): ReturnedLaborRule {
        if (rule.id === id) {
            return updateFunction(rule)
        }
        else return rule
    }

    const updated = overwriteRule(serviceLaborRule)
    cache.writeQuery<GetServiceLaborRuleQuery, GetServiceLaborRuleQueryVariables>({ query: GetServiceLaborRuleDocument, variables: { id }, data: { serviceLaborRule: updated } })
}


function CalculateMarginFields(price?: number, cost?: number, errorGate?: number): { marginError: boolean, marginNegative: boolean, marginText: string } {
    const gate = errorGate ?? 0
    const margin = (price === undefined || cost === undefined || price === 0) ? undefined : (price - cost) / price

    if (margin === undefined) return { marginError: false, marginNegative: false, marginText: " - ", }
    else {
        const marginText = `${(margin * 100).toFixed(2)}%`
        return { marginError: margin <= gate, marginNegative: margin < 0, marginText }
    }
}

export function LaborRatesRow({ laborRuleId }: { laborRuleId: number }) {

    const [productDialogOpen, setProductDialogOpen] = useState<boolean>(false)
    function closeDialog() {
        setProductDialogOpen(false)
    }

    //Load row data here
    const { data, loading, refetch } = useGetServiceLaborRuleQuery({ variables: { id: laborRuleId } })
    const [updateLaborRule] = useUpdateServiceProductOfferingMutation();
    const [tryDeleteLaborRule] = useTryRemoveLaborRuleMutation({
        variables: { ruleId: laborRuleId },
        onCompleted(data) {
            if (!data.tryRemoveLaborRule.isSuccessful) {
                window.alert(data.tryRemoveLaborRule.message)
            }
        },
        onError() {
            window.alert("Unable to delete.")
        },
        refetchQueries: [
            namedOperations.Query.GetServiceProductOfferingIds
        ],
        awaitRefetchQueries: true
    });
    console.log(data,"LBR")
    const reduxPrice = useAppSelector(selectPrice(laborRuleId))
    const reduxCost = useAppSelector(selectCost(laborRuleId))
    const reduxMinimum = useAppSelector(selectMinimum(laborRuleId))

    const isPriceDifferent = useAppSelector(selectIsPriceDifferent(laborRuleId)) ?? false
    const isCostDifferent = useAppSelector(selectIsCostDifferent(laborRuleId)) ?? false
    const isMinimumDifferent = useAppSelector(selectIsMinimumDifferent(laborRuleId)) ?? false

    const dispatch = useAppDispatch()
    const laborRule = data?.serviceLaborRule

    if (loading || laborRule === undefined) return <tr />


    const { productType,
        serviceType,
        description,
        jobServiceId,
        priceUnit,
        pricePerUnit: loadedPrice,
        costPerUnit: loadedCost,
        isDeleted,
        minimumInUnit: loadedMinimum } = laborRule

    const hideMinimum = !isMinimumDifferent && (loadedMinimum === undefined || loadedMinimum === null)

    const price = (isPriceDifferent) ? reduxPrice : loadedPrice
    const cost = (isCostDifferent) ? reduxCost : loadedCost
    const minimum = (isMinimumDifferent) ? reduxMinimum : (loadedMinimum ?? undefined)

    const { marginError, marginNegative, marginText } = CalculateMarginFields(price, cost, 0)

    function toggleDelete() {

        if (window.confirm(`Are you sure you wish to ${(isDeleted) ? "restore" : "delete"} labor rule with id: ${laborRuleId}?`)) {
            updateLaborRule({
                variables: { id: laborRuleId, isDeleted: !isDeleted },
                update(cache, result) {
                    console.log(cache)
                    cache.modify(
                        {
                            fields: {
                                serviceProductOfferingIds(existing = []) {
                                    console.log(existing)
                                    const { id } = result.data?.updateServiceProductOffering.updated!
                                    if (id !== undefined) {
                                        const removeFilter = (i: number) => i !== id
                                        return [...existing.filter(removeFilter)]
                                    }
                                    else return existing
                                }
                            }
                        });
                    updateLaborRulePostMutation({
                        cache,
                        id: laborRuleId,
                        updateFunction: (l) => ({ ...l, isDeleted: result.data?.updateServiceProductOffering.updated.isDeleted! })
                    })
                }
            })
        }
    }

    function tryPermDelete() {
        if (window.confirm("Are you sure you wish to remove this item forever?")) {
            tryDeleteLaborRule()
        }
    }

    function openMinimumEditor() {
        dispatch(changeMinimum(laborRuleId ?? 0))
    }

    function submitPrice() {
        updateLaborRule({ variables: { id: laborRuleId, price: price } }).then(
            (payload) => {
                var similarIds = payload.data?.updateServiceProductOffering.similar ?? []
                if (similarIds.length > 0 && window.confirm("Would you like to update matching prices?")) {
                    similarIds.forEach(simId =>
                        updateLaborRule({
                            variables: { id: simId, price: price },
                            update(cache, result) {
                                updateLaborRulePostMutation({
                                    cache,
                                    id: simId,
                                    updateFunction: (lr) => ({ ...lr, pricePerUnit: result.data?.updateServiceProductOffering.updated.pricePerUnit! })
                                })
                            }
                        }).then(
                            () => dispatch(resetPrice(simId)))
                    )
                }
                refetch().then(
                    () => dispatch(resetPrice(laborRuleId)))
            });
    }

    function submitCost() {
        updateLaborRule({ variables: { id: laborRuleId, cost: cost } }).then(
            (payload) => {
                var similarIds = payload.data?.updateServiceProductOffering.similar ?? []
                if (similarIds.length > 0 && window.confirm("Would you like to update matching costs?")) {
                    similarIds.forEach(simId =>
                        updateLaborRule({
                            variables: { id: simId, cost: cost },
                            update(cache, result) {
                                updateLaborRulePostMutation({
                                    cache,
                                    id: simId,
                                    updateFunction: (lr) => ({ ...lr, costPerUnit: result.data?.updateServiceProductOffering.updated.costPerUnit! })
                                })
                            }
                        }).then(
                            () => dispatch(resetCost(simId)))
                    )
                }
                refetch().then(
                    () => dispatch(resetCost(laborRuleId)))
            });
    }

    function submitMinimum() {
        updateLaborRule({ variables: { id: laborRuleId, minimum: minimum ?? 0 } }).then(
            (payload) => {
                var similarIds = payload.data?.updateServiceProductOffering.similar ?? []
                if (similarIds.length > 0 && window.confirm("Would you like to update matching minimums?")) {
                    similarIds.forEach(simId =>
                        updateLaborRule({
                            variables: { id: simId, minimum: minimum ?? 0 },
                            update(cache, result) {
                                updateLaborRulePostMutation({
                                    cache,
                                    id: simId,
                                    updateFunction: (lr) => ({ ...lr, minimumInUnit: result.data?.updateServiceProductOffering.updated.minimumInUnit })
                                })
                            }
                        }).then(
                            () => dispatch(resetMinimum(simId)))
                    )
                }
                refetch().then(
                    () => dispatch(resetMinimum(laborRuleId)))
            });
    }

    const serviceName = `${description} - ${serviceType}`

    return (
        <>
            <tr>
                <td className="shrink" align="center">
                    <div className="flex-row">
                        <IconButton onClick={toggleDelete}>{isDeleted ? <RestoreFromTrashIcon /> : <DeleteIcon />}</IconButton>
                        {isDeleted && <IconButton onClick={tryPermDelete}><DeleteForeverIcon style={{ color: "#D2122E" }} /></IconButton>}
                    </div>
                </td>
                <td align="center">{productType}</td>
                <td align="center">{serviceType}</td>
                <td align="center">{description}
                    <Tooltip title={`Edit additional products available for ${serviceName}`}>
                        <IconButton onClick={() => setProductDialogOpen(true)}>
                            <Launch />
                        </IconButton>
                    </Tooltip>
                </td>
                <td align="center">{priceUnit}</td>
                <td align="left" className="shrink">
                    <MoneyNumberFormat
                        style={{ width: "5rem" }}
                        value={price}
                        onValueChange={(values) => {
                            if (values.floatValue !== loadedPrice) dispatch(changePrice(laborRuleId ?? 0, values.floatValue))
                        }} />
                    <Button onClick={() => dispatch(resetPrice(laborRuleId))} style={{ visibility: (isPriceDifferent ? "visible" : "hidden"), minWidth: 0, width: "2em" }} >
                        X
                    </Button>
                    <Button onClick={submitPrice} style={{ visibility: (isPriceDifferent ? "visible" : "hidden") }} >
                        Update
                    </Button>
                </td>
                <td align="left" className="shrink">
                    <MoneyNumberFormat
                        style={{ width: "5rem" }}
                        value={cost}
                        onValueChange={(values) => {
                            if (values.floatValue !== loadedCost) dispatch(changeCost(laborRuleId ?? 0, values.floatValue))
                        }} />
                    <Button onClick={() => dispatch(resetCost(laborRuleId))} style={{ visibility: (isCostDifferent ? "visible" : "hidden"), minWidth: 0, width: "2em" }}>
                        X
                    </Button>
                    <Button onClick={submitCost} style={{ visibility: (isCostDifferent ? "visible" : "hidden") }}>
                        Update
                    </Button>
                </td>
                <td align="left" className="shrink">
                    <Button style={{ width: "10rem" }} variant="contained" hidden={!hideMinimum} onClick={openMinimumEditor}>
                        No Minimum
                    </Button>
                    <MinimumAmountFormat
                        hidden={hideMinimum}
                        minimum={minimum?.toString() ?? ""}
                        price={price?.toString() ?? ""}
                        unit={priceUnit}
                        highlightErrors={false}
                        onValueChange={(values) => {
                            if (values.floatValue !== loadedMinimum) dispatch(changeMinimum(laborRuleId ?? 0, values.floatValue))
                        }}
                        autoFocus={isMinimumDifferent} />
                    <Button onClick={() => dispatch(resetMinimum(laborRuleId))} style={{ visibility: (isMinimumDifferent ? "visible" : "hidden"), minWidth: 0, width: "2em" }}>
                        X
                    </Button>
                    <Button onClick={submitMinimum} style={{ visibility: (isMinimumDifferent ? "visible" : "hidden") }}>
                        Update
                    </Button>
                </td>
                <td align="center" style={{ color: (marginError) ? 'red' : 'black', fontWeight: (marginNegative) ? "bold" : "inherit" }}>{marginText}</td>
            </tr>
            <ProductsForServiceDialog
                pricingUnit={priceUnit}
                open={productDialogOpen}
                onClose={closeDialog}
                serviceName={serviceName}
                jobServiceId={jobServiceId} />
        </>
    )
}