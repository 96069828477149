import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { FormControl, InputLabel, MenuItem, Select, Typography } from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import Loading from "Components/Loading";
import FlatButton from "FlatComponents/Button/FlatButton";
import {
    GetJobWorkOrdersQuery,
    useGetJobInvoiceDataQuery,
    useGetJobWorkOrdersQuery,
    useGetProjectCoordinatorFromConfigurationQuery
} from "generated/graphql";
import { formatAppointmentDateStringAbbreviatedShortened } from "Globals/DateAndTimeHelpers";
import {
    isNotEmptyString,
    isNotNullOrUndefined,
    isNullOrUndefined
} from "Globals/GenericValidators";
import { printPickSheetPath, printWorkOrderPath } from "Globals/PathStrings";
import { useState } from "react";
import { useAppSelector } from "Redux/hooks";
import { getNameOfArea } from "Redux/JobReducerDataStructures/AreaType";
import { selectJobConfigurationId, selectSAHNumber } from "Redux/jobTrackerReducer";
import { strToLIs } from "../../Breakdown/Notes/EditableNote";
import MobileWorkOrder from "./MobileWorkOrder";
import PrintableWorkOrder from "./PrintableWorkOrder";

export function getNotes(
    workOrder: GetJobWorkOrdersQuery["jobWorkOrders"][number]
): ReactJSXElement[] {
    let items: ReactJSXElement[] = [];

    // job notes
    if (isNotNullOrUndefined(workOrder.jobNotes) && isNotEmptyString(workOrder.jobNotes!.trim())) {
        items.push(...strToLIs(workOrder.jobNotes!, "jobnotes"));
    }

    // area and room notes
    // TODO: remove area notes
    workOrder.areaBreakdowns.forEach((ab) => {
        if ((ab.areaNotes ?? "") !== "") {
            items.push(
                <li key={ab.areaId + "area"}>
                    <b>{`[${getNameOfArea(ab.areaLabels)}]`}</b>
                </li>
            );
        }

        if (isNotNullOrUndefined(ab.rooms)) {
            ab.rooms!.filter((r) => isNotEmptyString(r.notes)).forEach((r) => {
                items.push(<b key={r.id + "room"}>{`[${getNameOfArea(r.labels)}]`}</b>);
                items.push(...strToLIs(r.notes, r.id));
            });
        }
    });

    // installation notes
    if (
        isNotNullOrUndefined(workOrder.installationNotes) &&
        isNotEmptyString(workOrder.installationNotes!)
    ) {
        items.push(<b key={"installation"}>Installation</b>);
        items.push(...strToLIs(workOrder.installationNotes!, "installationNotes"));
    }

    return items;
}

export default function WorkOrdersPage() {
    const jobConfigurationId = useAppSelector(selectJobConfigurationId);
    const sahNumber = useAppSelector(selectSAHNumber);

    const { data: projectCoordinatorData } = useGetProjectCoordinatorFromConfigurationQuery({
        variables: { jobConfigurationId: jobConfigurationId ?? 0 },
        skip: isNullOrUndefined(jobConfigurationId),
    });
    const projectCoordinator = projectCoordinatorData?.projectCoordinatorFromConfiguration;

    const { data: jobData } = useGetJobInvoiceDataQuery({
        variables: { jobConfigurationId: jobConfigurationId ?? 0 },
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-only",
        skip: (jobConfigurationId ?? 0) < 1,
    });
    const customer = jobData?.jobConfigurationHeader.customer;
    const salesReps = jobData?.jobConfigurationHeader.assignedSalespeople.map(
        (s) => `${s.firstName} ${s.lastName.charAt(0)}`
    );

    const { data: workOrdersData } = useGetJobWorkOrdersQuery({
        variables: { jobConfigurationId: jobConfigurationId! },
        skip: isNullOrUndefined(jobConfigurationId),
        fetchPolicy: "no-cache",
        onCompleted: (res) => {
            setSelectedWorkOrderApptId(res.jobWorkOrders[0]?.installationAppointmentId ?? 0);
            setSelectedWorkOrder(res.jobWorkOrders[0] ?? undefined);
        },
    });
    const workOrders = workOrdersData?.jobWorkOrders;
    const [selectedWorkOrderApptId, setSelectedWorkOrderApptId] = useState<number>(0);
    const [selectedWorkOrder, setSelectedWorkOrder] = useState<
        GetJobWorkOrdersQuery["jobWorkOrders"][number] | undefined
    >(undefined);

    function handleWorkOrderSelectionChanged(apptId: number) {
        setSelectedWorkOrderApptId(apptId);
        setSelectedWorkOrder(
            workOrders?.find((wo) => wo.installationAppointmentId === apptId) ?? undefined
        );
    }

    return (
        <div className="margin-top-md">
            {(workOrders?.length ?? 0) === 0 ? (
                <p>
                    No installation appointments have been scheduled. To generate a work order,
                    schedule an installation.
                </p>
            ) : (
                isNotNullOrUndefined(customer) &&
                isNotNullOrUndefined(salesReps) &&
                isNotNullOrUndefined(workOrders) && (
                    <>
                        <div className="flex-row align-items-baseline flex-gap-sm">
                            <FormControl>
                                <InputLabel id="wo-select">Select Workorder</InputLabel>
                                <Select
                                    labelId="wo-select"
                                    value={selectedWorkOrderApptId}
                                    label="Select Workorder"
                                    onChange={(e) =>
                                        handleWorkOrderSelectionChanged(e.target.value as number)
                                    }
                                >
                                    <MenuItem
                                        value={0}
                                        disabled
                                    >
                                        Select Work Order
                                    </MenuItem>
                                    {workOrders!.map((wo) => {
                                        let contractorName = `${wo.contractor.firstName} ${wo.contractor.lastName}`;
                                        let dateStr = formatAppointmentDateStringAbbreviatedShortened(wo.dates);

                                        // need installationAppointmentId because is used when generating a single workorder for printing
                                        return (
                                            <MenuItem
                                                key={`wo-${wo.installationAppointmentId}`}
                                                value={wo.installationAppointmentId}
                                            >{`${contractorName} | ${dateStr}`}</MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                            {isNotNullOrUndefined(selectedWorkOrderApptId) &&
                                selectedWorkOrderApptId > 0 && (
                                    <div className="flex-row flex-centered flex-gap-xsm">
                                        <FlatButton
                                            variant="contained"
                                            color="secondary"
                                            onClick={() =>
                                                window.open(
                                                    `${printWorkOrderPath}/${jobConfigurationId}/${selectedWorkOrderApptId}`
                                                )
                                            }
                                        >
                                            <PrintIcon style={{ marginRight: ".7rem" }} />
                                            Workorder
                                        </FlatButton>
                                        
                                        <FlatButton
                                            variant="contained"
                                            color="secondary"
                                            onClick={() =>
                                                window.open(
                                                    `${printPickSheetPath}/${selectedWorkOrderApptId}`
                                                )
                                            }
                                        >
                                            <PrintIcon style={{ marginRight: ".7rem" }} />
                                            Pick Sheet
                                        </FlatButton>
                                    </div>
                                )}
                        </div>

                        {isNotNullOrUndefined(selectedWorkOrder) && (
                            <div className="flex-row justify-content-center margin-top-md margin-bottom-md">
                                <PrintableWorkOrder
                                    sahNum={sahNumber}
                                    customer={customer!}
                                    salesRepNames={salesReps!}
                                    projectCoordinatorName={
                                        isNullOrUndefined(projectCoordinator)
                                            ? ""
                                            : `${projectCoordinator!.firstName} ${projectCoordinator!.lastName.charAt(0)
                                              }`
                                    }
                                    contractorName={`${selectedWorkOrder!.contractor.firstName} ${
                                        selectedWorkOrder!.contractor.lastName
                                    }`}
                                    dates={selectedWorkOrder!.dates}
                                    hasFinancing={selectedWorkOrder!.hasFinancing}
                                    remainingCod={selectedWorkOrder!.remainingCod}
                                    areaBreakdowns={selectedWorkOrder!.areaBreakdowns}
                                    notes={getNotes(selectedWorkOrder!)}
                                />
                            </div>
                        )}
                    </>
                )
            )}
        </div>
    );
}

export interface WorkOrderProps {
    installationAppointmentId: number;
    jobConfigurationId: number;
    mobileFriendly?: boolean;
}

export function WorkOrder({
    installationAppointmentId,
    jobConfigurationId,
    mobileFriendly,
}: WorkOrderProps) {
    const { data: projectCoordinatorData, loading: loadingPC } =
        useGetProjectCoordinatorFromConfigurationQuery({
            variables: { jobConfigurationId: jobConfigurationId },
        });

    const { data: jobData, loading: loadingInv } = useGetJobInvoiceDataQuery({
        variables: { jobConfigurationId: jobConfigurationId ?? 0 },
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-only",
        skip: (jobConfigurationId ?? 0) < 1,
    });

    // TODO: need to decouple this from the ServiceForRoomBreakdown type because it contains information like LaborPricePerUnit, which is completely irrelevant here
    const { data: workOrdersData, loading: loadingWO } = useGetJobWorkOrdersQuery({
        variables: { jobConfigurationId: jobConfigurationId! },
        fetchPolicy: "no-cache",
    });

    const projectCoordinator = projectCoordinatorData?.projectCoordinatorFromConfiguration;
    const customer = jobData?.jobConfigurationHeader.customer;
    const salesReps = jobData?.jobConfigurationHeader.assignedSalespeople.map(
        (s) => `${s.firstName} ${s.lastName}`
    );
    const sahNumber = jobData?.jobConfigurationHeader.appointmentNumber;
    const workOrder = workOrdersData?.jobWorkOrders.find(
        (wo) => wo.installationAppointmentId === installationAppointmentId
    );
    const remainingCod = workOrder?.remainingCod;
    

    const anythingMissing =
        isNullOrUndefined(projectCoordinator) ||
        isNullOrUndefined(customer) ||
        isNullOrUndefined(salesReps) ||
        isNullOrUndefined(sahNumber) ||
        isNullOrUndefined(workOrder);

    const anythingLoading = loadingPC || loadingInv || loadingWO;
    console.log(workOrder)
    return (
        <>
            {anythingLoading ? (
                <Loading />
            ) : anythingMissing ? (
                <Typography>Could not load work order</Typography>
            ) : mobileFriendly ? (
                <MobileWorkOrder
                    sahNum={sahNumber!}
                    customer={customer!}
                    salesRepNames={salesReps!}
                    projectCoordinatorName={
                        isNullOrUndefined(projectCoordinator)
                            ? ""
                            : `${projectCoordinator!.firstName} ${projectCoordinator!.lastName}`
                    }
                    contractorName={`${workOrder!.contractor.firstName} ${
                        workOrder!.contractor.lastName
                    }`}
                    dates={workOrder!.dates}
                    remainingCod={remainingCod!}
                    hasFinancing={workOrder!.hasFinancing}
                    areaBreakdowns={workOrder!.areaBreakdowns}
                    notes={getNotes(workOrder!)}
                />
            ) : (
                <PrintableWorkOrder
                    sahNum={sahNumber!}
                    customer={customer!}
                    salesRepNames={salesReps!}
                    projectCoordinatorName={
                        isNullOrUndefined(projectCoordinator)
                            ? ""
                            : `${projectCoordinator!.firstName} ${projectCoordinator!.lastName}`
                    }
                    contractorName={`${workOrder!.contractor.firstName} ${
                        workOrder!.contractor.lastName
                    }`}
                    dates={workOrder!.dates}
                    hasFinancing={workOrder!.hasFinancing}
                    remainingCod={remainingCod!}
                    areaBreakdowns={workOrder!.areaBreakdowns}
                    notes={getNotes(workOrder!)}
                />
            )}
        </>
    );
}
