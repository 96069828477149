import { idUrlMask, twoIdUrlMask } from "Globals/Hooks";
import { breakdownPath, changeOrderPath, jobTrackerPath, measurePath, originalContractPath, picturesPath, printPickSheetPath, printWorkOrderPath, serviceOrderPath, workOrdersPath } from "Globals/PathStrings";
import { Route, Switch } from "react-router-dom";
import JobBreakdownPage from "../Breakdown/JobBreakdownPage";
import ChangeOrderPage from "../ChangeOrder/ChangeOrderPage";
import JobInstallationTrackerPage from "../JobInstallationTrackerPage";
import JobPicturesPage from "../JobPictures/JobPicturesPage";
import ServiceOrderPage, { ServiceOrderStyleSelect } from "../Services/ServiceOrderPage";
import ContractDrawingViewer from "../ContractDrawingViewer";
import JobTrackerPage from "./JobTrackerPage";
import OriginalContractPage from "./OriginalContractPage";
import PrintWorkOrderPage from "./WorkOrder/PrintWorkOrderPage";
import PrintablePickSheet from "./WorkOrder/PrintablePickSheet";
import WorkOrdersPage from "./WorkOrder/WorkOrdersPage";
import { useGetJobBreakdownQuery } from 'generated/graphql';
import { useAppSelector } from "Redux/hooks";
import { selectJobConfigurationId } from "Redux/jobTrackerReducer";

export default function JobTrackerRouter() {

    const jobConfigurationId = useAppSelector(selectJobConfigurationId);

    const { data, loading, error } = useGetJobBreakdownQuery({
        variables: { jobConfigurationId: jobConfigurationId },
        skip: jobConfigurationId < 1,
    });

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error fetching job breakdown</div>;

    const areaBreakdowns = data?.jobBreakdown?.areaBreakdowns ?? [];
    return (
        <Switch>
            <Route path={printWorkOrderPath + twoIdUrlMask}>
                <PrintWorkOrderPage />
            </Route>
            <Route path={printPickSheetPath + idUrlMask}>
                <PrintablePickSheet />
            </Route>
            <Route path={jobTrackerPath}>
                <Switch>
                    <Route path={originalContractPath + idUrlMask}>
                        <JobTrackerPage title="Original Contract">
                            <OriginalContractPage />
                        </JobTrackerPage>
                    </Route>
                    <Route path={breakdownPath + idUrlMask}>
                        <JobTrackerPage title="Break Down">
                            <JobBreakdownPage />
                        </JobTrackerPage>
                    </Route>
                    <Route path={measurePath + idUrlMask}>
                        <JobTrackerPage title="Measure">
                            <ContractDrawingViewer areaBreakdowns={areaBreakdowns} />
                        </JobTrackerPage>
                    </Route>
                    <Route path={workOrdersPath + idUrlMask}>
                        <JobTrackerPage title="Work Orders">
                            <WorkOrdersPage />
                        </JobTrackerPage>
                    </Route>
                    <Route path={changeOrderPath + idUrlMask}>
                        <JobTrackerPage title="Change Order">
                            <ChangeOrderPage />
                        </JobTrackerPage>
                    </Route>
                    <Route path={serviceOrderPath + idUrlMask}>
                        <JobTrackerPage decorationElement={<ServiceOrderStyleSelect />} title="Service Order">
                            <ServiceOrderPage />
                        </JobTrackerPage>
                    </Route>
                    <Route path={picturesPath + idUrlMask}>
                        <JobTrackerPage title="Pictures">
                            <JobPicturesPage />
                        </JobTrackerPage>
                    </Route>
                    <Route path={jobTrackerPath + idUrlMask} >
                        <JobTrackerPage title="Job Tracker" >
                            <JobInstallationTrackerPage />
                        </JobTrackerPage>
                    </Route>
                </Switch>
            </Route>
        </Switch>
    )
}