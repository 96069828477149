import { DayValue } from "@hassanmojab/react-modern-calendar-datepicker";
import { Button, FormGroup, IconButton } from "@material-ui/core";
import CalendarIcon from "@material-ui/icons/CalendarToday";
import FlatSection from "FlatComponents/Layout/FlatSection";
import { dayToIso } from "Globals/DateAndTimeHelpers";
import { isNotNullOrUndefined, isNullOrUndefined } from "Globals/GenericValidators";
import { calendarPath } from "Globals/PathStrings";
import { useAppHistory } from "Globals/routingHooks";
import {
    GetInstallationAppointmentsQuery,
    namedOperations,
    useAddInstallationAppointmentMutation, useGetCwcNumberQuery,
    useGetInstallationCodBoundsForNewLazyQuery
} from "generated/graphql";
import { useEffect, useMemo, useState } from "react";
import { Breakdowns } from "../BreakdownTableUtils";
import AppointLengthEstimator from "./AppointmentLengthEstimator";
import { InstallationSummaries } from "./InstallationSummaries";
import ProductTypeCheckBox from "./ProductTypeCheckbox";
import RoomCheckBox from "./RoomCheckBox";
import { SchedulingPopout } from "./SchedulingPopout";
import {
    buildInstallationCandidates,
    buildInstallationSummaries,
    roomIdsFromStaged,
    stagedForSchedulingValid
} from "./utils/helpers";
import {
    ProductTypeInstallationCandidates,
    ScheduledInstallationSummary,
    StagedAppointmentSchedulings
} from "./utils/installationSchedulingTypes";

interface InstallationSchedulingPaneProps {
    jobId: number;
    contractId: number;
    jobConfigurationId: number;
    areaBreakdowns: Breakdowns;
    installationAppointments: GetInstallationAppointmentsQuery["installationAppointments"];
}

export default function InstallationSchedulingPane({
    contractId,
    areaBreakdowns,
    jobConfigurationId,
    installationAppointments,
    jobId,
}: InstallationSchedulingPaneProps) {
    const history = useAppHistory();

    const [installationSummaries, setInstallationSummaries] = useState<ScheduledInstallationSummary[]>([]);
    const [installationCandidates, setInstallationCandidates] = useState<ProductTypeInstallationCandidates>({});    
    
    const { data: cwcDeadlineData } = useGetCwcNumberQuery({
        variables: { jobId: jobId },
        skip: !jobId,
    });
    
    const cwcNumber = cwcDeadlineData?.cWCNumber.cwcNumber;

    useEffect(() => {
        let summaries = buildInstallationSummaries(installationAppointments);
        let candidates = buildInstallationCandidates(areaBreakdowns, summaries);

        setInstallationSummaries(summaries);
        setInstallationCandidates(candidates);
    }, [installationAppointments, areaBreakdowns]);
    
    // area selected for scheduling, as well as the square footage being scheduled
    const [stagedForScheduling, setStagedForScheduling] = useState<StagedAppointmentSchedulings>({});

    const isStagedForSchedulingValid = stagedForSchedulingValid(stagedForScheduling);

    function isDifferentProductTypeSelected(productTypeId: number): boolean {
        return Object.keys(stagedForScheduling).some(
            // Checks if statges for scheduling contains a product type other than provided id
            (checkAreaId) => stagedForScheduling[+checkAreaId].productTypeId !== productTypeId
        );
    }

    function onTogglePtype(pTypeId: number) {
        let { ...updatedSelection } = stagedForScheduling;
        if (Object.keys(stagedForScheduling).map(ptId => +ptId).includes(pTypeId)) {
            // copy the object, but without the ID for the product type that is being toggled
            let { [pTypeId]: _, ...removalUpdatedSelection } = stagedForScheduling;
            updatedSelection = { ...removalUpdatedSelection };
            if (Object.keys(updatedSelection).length === 0) {
                setSelectedDate(null);
            }
        } else {
            // prevent installation appointments from being created with multiple product types
            if (isDifferentProductTypeSelected(pTypeId)) return;

            const thisPtypeGroup = installationCandidates[pTypeId];
            const allRoomIds: number[] = Object.keys(thisPtypeGroup.rooms).map((id) => +id);

            updatedSelection[pTypeId] = {
                roomIds: allRoomIds,
                productTypeId: pTypeId,
            };
        }

        setStagedForScheduling(updatedSelection);
        clearSubmissionStates();
    }

    function onToggleRoom(roomId: number, pTypeId: number) {
        let updatedSelection: StagedAppointmentSchedulings = { ...stagedForScheduling };
        let pTypeEntry = updatedSelection[pTypeId];

        if (pTypeEntry.roomIds.includes(roomId)) {
            // remove the room from the area
            let filtered = pTypeEntry.roomIds.filter((id) => id !== roomId);
            updatedSelection[pTypeId].roomIds = filtered;

            // no rooms are selected from the area now, so this is like clicking on the area check box
            if (filtered.length === 0) {
                onTogglePtype(pTypeId);
                return;
            }
        } else {
            // To prevent installation appointments from being created with multiple product types,
            // When trying to select an area, this performs the type check and prevents it if it fails
            if (isDifferentProductTypeSelected(pTypeId)) return;

            // add the room to the area
            pTypeEntry.roomIds.push(roomId);
        }

        // FIXME: this needs to depend on the new list of product types present
        setStagedForScheduling(updatedSelection);
        clearSubmissionStates();
    }

    // the default object type provided here tells the Calendar component to select a date range
    const [selectedDate, setSelectedDate] = useState<DayValue>(null);

    const [cod, setCod] = useState<number | undefined>(undefined);
    const [codBounds, setCodBounds] = useState({ lowerBound: -1, upperBound: -1 });
    const [getCodBounds] = useGetInstallationCodBoundsForNewLazyQuery({
        onCompleted: (data) => {
            setCodBounds(data.installationCodBoundsForNew);
        },
        onError: () => alert("Could not calculate COD requirements."),
    });

    const roomIds = useMemo(() => roomIdsFromStaged(stagedForScheduling), [stagedForScheduling])

    // update COD whenever the installation candidates change
    useEffect(() => {
        if (selectedDate && roomIds.length > 0) {
            getCodBounds({
                variables: {
                    jobConfigurationId: jobConfigurationId,
                    startDate: dayToIso(selectedDate),
                    roomIds: roomIds,
                },
            }).then(
                (res) =>
                    isNullOrUndefined(cod) &&
                    setCod(res.data?.installationCodBoundsForNew.upperBound ?? 0)
            );
        } else {
            setCod(undefined);
        }
    }, [roomIds, getCodBounds, jobConfigurationId, selectedDate, cod]);

    const [installationNotes, setInstallationNotes] = useState("");
    const [submissionError, setSubmissionError] = useState(false);
    const [submissionSuccess, setSubmissionSuccess] = useState(false);

    // called when any scheduling value changes to prevent ambiguity about results of successive clicks of the submission button
    function clearSubmissionStates() {
        setSubmissionError(false);
        setSubmissionSuccess(false);
    }

    function onSchedulingFailed() {
        setSubmissionError(true);
        setSubmissionSuccess(false);
        setTimeout(() => setSubmissionError(false), 5000); // clear error message after 5 secs
    }

    function onSchedulingSucceeded() {
        setSubmissionError(false);
        setSubmissionSuccess(true);
        setStagedForScheduling({});
        setSelectedDate(null);
        setStagedForScheduling({});
        setInstallationNotes("");
        setCod(undefined);
        setCodBounds({ lowerBound: -1, upperBound: -1 });
        setTimeout(
            () =>
                document
                    .getElementById("installation-scheduling-container")
                    ?.scrollIntoView({ block: "end" }),
            500
        );
        setTimeout(() => setSubmissionSuccess(false), 5000); // clear success message after 5 secs
    }

    const [addInstallationAppointment, { loading: appointmentSubmitting }] =
        useAddInstallationAppointmentMutation({
            refetchQueries: [
                namedOperations.Query.GetInstallationAppointments,
                namedOperations.Query.GetCwcNumber,
                namedOperations.Query.GetInstallationAppointmentsAfterDate,
            ],
            onError: onSchedulingFailed,
            onCompleted: (c) => {
                c.addInstallationAppointment ? onSchedulingSucceeded() : onSchedulingFailed();
            },
            awaitRefetchQueries: true,
        });
    
    function canSubmitInstallation() {
        if (!isStagedForSchedulingValid) {
            alert("Select areas/rooms for installation");
            return false;
        }

        if (isNullOrUndefined(selectedDate)) {
            alert("Please select a preferred date");
            return false;
        }

        if (isNullOrUndefined(cod) || cod! < 0) {
            alert("Please enter an installation COD value");
            return false;
        }

        const lowerBound = codBounds.lowerBound;
        const upperBound = codBounds.upperBound;
        if (!(cod! <= upperBound && cod! >= lowerBound)) {
            if (upperBound === lowerBound) {
                alert(`Installation COD must be set to $${upperBound}`);
            } else {
                alert(`Installation COD must be set between $${lowerBound} and $${upperBound}`);
            }

            return false;
        }

        return true;
    }

    function onSubmitInstallation() {
        if (canSubmitInstallation()) {
            let roomIds: number[] = [];
            Object.keys(stagedForScheduling).forEach((areaId) => {
                roomIds = roomIds.concat(stagedForScheduling[+areaId].roomIds.map((id) => +id));
            });

            addInstallationAppointment({
                variables: {
                    jobContractId: contractId!,
                    startDate: dayToIso(selectedDate!),
                    notes: installationNotes,
                    cod: cod!,
                    roomIds: roomIds,
                },
            });
        }
    }
        
    return (
        <FlatSection
            header="Installation"
            endAdornment={
                <IconButton
                    style={{padding: 0}}
                    onClick={() => history.push(calendarPath)}
                >
                    <CalendarIcon htmlColor="black"/>
                </IconButton>
            }
            className="fill-height"
        >
            <div id="installation-scheduling-container flat-font">
                {Object.keys(installationCandidates).length > 0 && (
                    <div className="margin-bottom-sm align-self-flex-start flat-font">
                        <div className="flex-row">
                            <p className="bold-text margin-none">Schedule Installation</p>

                            {isNotNullOrUndefined(cwcNumber) && (
                                <p className=" margin-left-sm margin-vertical-none success-text">
                                    {`[CWC ${cwcNumber}]`}
                                </p>
                            )}
                        </div>

                        <div className="flex-column flex-gap-sm">
                            <FormGroup className="flex-column">
                                {Object.keys(installationCandidates).map((pTypeId) => {
                                    const {
                                        rooms: roomSummaries,
                                        productType,
                                        totalSqft,
                                    } = installationCandidates[+pTypeId];

                                    const isDisabled = isDifferentProductTypeSelected(+pTypeId);
                                    let allRoomsSelected = false;
                                    let someRoomsSelected = false;
                                    const areaHasStagedRoom = Object.keys(stagedForScheduling).includes(pTypeId);
                                    if (areaHasStagedRoom) {
                                        const numRoomsForProductType =
                                            Object.keys(roomSummaries).length;
                                        const numRoomsStaged = Object.keys(
                                            stagedForScheduling[+pTypeId].roomIds
                                        ).length;

                                        if (numRoomsStaged < numRoomsForProductType) {
                                            someRoomsSelected = true;
                                        } else if (numRoomsStaged === numRoomsForProductType) {
                                            allRoomsSelected = true;
                                        }
                                    }

                                    const remainingSqft = +installationCandidates[+pTypeId].totalPendingSqft.toFixed(0);
                                    
                                    const label = (<div className="flex-row flex-gap-sm flat-font">
                                        <p className="margin-none bold-text">{productType}</p>
                                        {remainingSqft === totalSqft ? (
                                            <p className="margin-none">{totalSqft.toFixed(0)} sqft</p>
                                        ) : (
                                            <p className="margin-none">{remainingSqft.toFixed(0)} sqft remaining</p>
                                        )}
                                    </div>)

                                    return (
                                        <ProductTypeCheckBox
                                            someRoomsSelected={someRoomsSelected}
                                            allRoomsSelected={allRoomsSelected}
                                            pTypeHasStagedRoom={areaHasStagedRoom}
                                            label={label}
                                            productTypeId={+pTypeId}
                                            onTogglePtype={onTogglePtype}
                                            disabled={isDisabled}
                                            key={`ptcb-${pTypeId}`}
                                        >
                                            {Object.keys(roomSummaries).map((roomId) => {
                                                let roomSummary = roomSummaries[+roomId];
                                                let roomIsStaged = false;
                                                if (isNotNullOrUndefined(stagedForScheduling[+pTypeId]?.roomIds)) {
                                                    roomIsStaged = stagedForScheduling[+pTypeId].roomIds!.includes(+roomId) ?? false;
                                                }

                                                const roomLabel = (
                                                    <div className="flex-row flat-font">
                                                        <p className="margin-none">{roomSummary.roomName}</p>
                                                        <p className="margin-vertical-none margin-left-sm">{roomSummary.sqft.toFixed(0)} sqft</p>
                                                    </div>
                                                )

                                                return (
                                                    <RoomCheckBox
                                                        areaId={roomSummary.areaId}
                                                        roomId={+roomId}
                                                        productTypeId={+pTypeId}
                                                        roomIsStaged={roomIsStaged}
                                                        roomLabel={roomLabel}
                                                        onToggleRoom={onToggleRoom}
                                                        disabled={isDisabled}
                                                        key={`rcb-${roomId}`}
                                                    />
                                                );
                                            })}
                                        </ProductTypeCheckBox>
                                    );
                                })}

                                {submissionError && (
                                    <p className="error-text">Could not schedule installation</p>
                                )}
                                {submissionSuccess && (
                                    <p className="success-text">Installation scheduled</p>
                                )}
                            </FormGroup>

                            {isStagedForSchedulingValid && (
                                <SchedulingPopout
                                    showCwcButton
                                    cwcNumber={cwcNumber}
                                    jobId={jobId!}
                                    selectedDate={selectedDate}
                                    setSelectedDate={setSelectedDate}
                                    cod={cod}
                                    setCod={setCod}
                                    codBounds={codBounds}
                                    calendarId="new-appt-calendar"
                                    popoutId="new-appt-popout"
                                    key="new-appt"
                                    submitAdornment={
                                        <>
                                            <AppointLengthEstimator roomIds={roomIds}/>
                                            <Button
                                                className="fit-content-width margin-top-xsm"
                                                onClick={() => onSubmitInstallation()}
                                                variant="contained"
                                                disabled={
                                                    isNullOrUndefined(selectedDate) ||
                                                    appointmentSubmitting ||
                                                    isNullOrUndefined(cod) ||
                                                    cod! < 0
                                                }
                                            >
                                                Schedule Installation
                                            </Button>
                                        </>
                                    }
                                />
                            )}
                        </div>
                    </div>
                )}

                {installationSummaries.length > 0 && (
                    <InstallationSummaries installationSummaries={installationSummaries}/>
                )}
            </div>
        </FlatSection>
    );
}
