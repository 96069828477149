import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { KeyedChangedNumber, KeyedChangedString } from './productCostsReducer'
import { RootState } from './store'

const modes: boolean[][] = [
    //Style, Cost, OP, Factor, OP, Shipping, OP, Price, Promo, From, To, Submit          
    [true, true, true, true, true, true, true, true, true, true, true, true, true, true, true], //All mode
    [true, false, false, false, false, false, false, true, true, true, true, true, true, true], //Promo 
    [true, true, true, true, true, true, true, true, true, true, false, false, false, false, true] //Pricing
]

interface ProductPricingState { //Defined so that create slice can infer the type
    showTrash: boolean,
    changedFactors: KeyedChangedNumber,
    changedShipping: KeyedChangedNumber,
    changedPrices: KeyedChangedNumber,
    changedPromoPrices: KeyedChangedNumber,
    changedStartDate: KeyedChangedString,
    changedEndDate: KeyedChangedString,
    productTypeId: number,
    viewMode: number
}

const initialState: ProductPricingState = {
    showTrash:false,
    changedFactors: {},
    changedShipping: {},
    changedPrices: {},
    changedPromoPrices: {},
    changedStartDate: {},
    changedEndDate: {},
    productTypeId: 1,
    viewMode: 2
}

//A slice is a collection of reducer logic and actions. It will be combined to form the store in ./store
export const productPricingSlice = createSlice({
    name: "productPricing",
    initialState,
    reducers: {
        setShowTrash(state, action: PayloadAction<boolean>) {
            state.showTrash = action.payload
        },
        changePricingFactor: {
            reducer(state, action: PayloadAction<{ value?: number, rowId: number }>) {
                const { value, rowId } = action.payload
                state.changedFactors[rowId] = { value, changed: true }
            },
            prepare(rowId: number, factor?: number) {
                return { payload: { value: factor, rowId } }
            }
        },
        resetPricingFactor: {
            reducer(state, action: PayloadAction<{ rowId: number }>) {
                const { rowId } = action.payload
                state.changedFactors[rowId] = {}
            },
            prepare(rowId: number) {
                return { payload: { rowId } }
            }
        },
        changePricingShipping: {
            reducer(state, action: PayloadAction<{ value?: number, rowId: number }>) {
                const { value, rowId } = action.payload
                state.changedShipping[rowId] = { value, changed: true }
            },
            prepare(rowId: number, shipping?: number) {
                return { payload: { value: shipping, rowId } }
            }
        },
        resetPricingShipping: {
            reducer(state, action: PayloadAction<{ rowId: number }>) {
                const { rowId } = action.payload
                state.changedShipping[rowId] = {}
            },
            prepare(rowId: number) {
                return { payload: { rowId } }
            }
        },
        changePricingPrices: {
            reducer(state, action: PayloadAction<{ value?: number, rowId: number }>) {
                const { value, rowId } = action.payload
                state.changedPrices[rowId] = { value, changed: true }
            },
            prepare(rowId: number, prices?: number) {
                return { payload: { value: prices, rowId } }
            }
        },
        resetPricingPrices: {
            reducer(state, action: PayloadAction<{ rowId: number }>) {
                const { rowId } = action.payload
                state.changedPrices[rowId] = {}
            },
            prepare(rowId: number) {
                return { payload: { rowId } }
            }
        },
        changePricingPromoPrices: {
            reducer(state, action: PayloadAction<{ value?: number, rowId: number }>) {
                const { value, rowId } = action.payload
                state.changedPromoPrices[rowId] = { value, changed: true }
            },
            prepare(rowId: number, prices?: number) {
                return { payload: { value: prices, rowId } }
            }
        },
        resetPricingPromoPrices: {
            reducer(state, action: PayloadAction<{ rowId: number }>) {
                const { rowId } = action.payload
                state.changedPromoPrices[rowId] = {}
            },
            prepare(rowId: number) {
                return { payload: { rowId } }
            }
        },
        changePricingStartDate: {
            reducer(state, action: PayloadAction<{ value?: string, rowId: number }>) {
                const { value, rowId } = action.payload
                state.changedStartDate[rowId] = { value, changed: true }
            },
            prepare(rowId: number, startDate?: string) {
                return { payload: { value: startDate, rowId } }
            }
        },
        resetPricingStartDate: {
            reducer(state, action: PayloadAction<{ rowId: number }>) {
                const { rowId } = action.payload
                state.changedStartDate[rowId] = {}
            },
            prepare(rowId: number) {
                return { payload: { rowId } }
            }
        },
        changePricingEndDate: {
            reducer(state, action: PayloadAction<{ value?: string, rowId: number }>) {
                const { value, rowId } = action.payload
                state.changedEndDate[rowId] = { value, changed: true }
            },
            prepare(rowId: number, startDate?: string) {
                return { payload: { value: startDate, rowId } }
            }
        },
        resetPricingEndDate: {
            reducer(state, action: PayloadAction<{ rowId: number }>) {
                const { rowId } = action.payload
                state.changedEndDate[rowId] = {}
            },
            prepare(rowId: number) {
                return { payload: { rowId } }
            }
        },
        setPricingProductTypeId(state, action: PayloadAction<number>) {
            state.productTypeId = action.payload
        },
        setPricingViewMode(state, action: PayloadAction<number>) {
            state.viewMode = action.payload
        },
        resetPricingRow(state, action: PayloadAction<number>){
            const id = action.payload

            state.changedEndDate[id] = {}
            state.changedStartDate[id] = {}
            state.changedPrices[id] = {}
            state.changedPromoPrices[id] = {}
            state.changedFactors[id] = {}
            state.changedShipping[id] = {}
        },
        resetPricePage(state) {
            state.changedEndDate = {}
            state.changedStartDate = {}
            state.changedPrices = {}
            state.changedPromoPrices = {}
            state.changedFactors = {}
            state.changedShipping = {}
        }
    }
})

export const {
    changePricingFactor, resetPricingFactor,
    changePricingShipping, resetPricingShipping,
    changePricingPrices, resetPricingPrices,
    changePricingPromoPrices, resetPricingPromoPrices,
    changePricingStartDate, resetPricingStartDate,
    changePricingEndDate, resetPricingEndDate,
    setPricingProductTypeId, setPricingViewMode,
    resetPricingRow, resetPricePage,setShowTrash,
} = productPricingSlice.actions //Unpacks the actions created in the slice

export const selectIsFactorDifferent = (id: number) => (state: RootState) => state.productPricing.changedFactors[id]?.changed
export const selectIsShippingDifferent = (id: number) => (state: RootState) => state.productPricing.changedShipping[id]?.changed
export const selectIsPriceDifferent = (id: number) => (state: RootState) => state.productPricing.changedPrices[id]?.changed
export const selectIsPromoPriceDifferent = (id: number) => (state: RootState) => state.productPricing.changedPromoPrices[id]?.changed
export const selectIsStartDateDifferent = (id: number) => (state: RootState) => state.productPricing.changedStartDate[id]?.changed
export const selectIsEndDateDifferent = (id: number) => (state: RootState) => state.productPricing.changedEndDate[id]?.changed
export const selectShowTrash = (state: RootState) => state.productPricing.showTrash
export const selectHideTrash = (state: RootState) => !state.productPricing.showTrash

export const selectPricingFactor = (id: number) => (state: RootState) => state.productPricing.changedFactors[id]?.value
export const selectPricingShipping = (id: number) => (state: RootState) => state.productPricing.changedShipping[id]?.value
export const selectPricingPrice = (id: number) => (state: RootState) => state.productPricing.changedPrices[id]?.value
export const selectPricingPromoPrice = (id: number) => (state: RootState) => state.productPricing.changedPromoPrices[id]?.value
export const selectPricingStartDate = (id: number) => (state: RootState) => state.productPricing.changedStartDate[id]?.value
export const selectPricingEndDate = (id: number) => (state: RootState) => state.productPricing.changedEndDate[id]?.value

export const selectPricingProductType = (state: RootState) => state.productPricing.productTypeId
export const selectPricingViewMode = (state: RootState) => state.productPricing.viewMode
export const selectPricingViewFilter = (state: RootState) => modes[state.productPricing.viewMode]

export default productPricingSlice.reducer