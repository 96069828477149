import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    AppliedDiscount,
    AppliedDiscountInput,
    Area,
    AreaInput,
    CarpetCut,
    CarpetCutInput,
    CarpetCutPiece,
    CarpetCutPieceInput,
    DiscountsOnJob,
    DiscountsOnJobInput,
    Financing,
    FinancingInput,
    FinancingOption,
    FinancingOptionInput,
    JobConfiguration,
    JobConfigurationInput,
    LabelForRoom,
    LabelForRoomInput,
    Maybe,
    Picture,
    PictureForRoom,
    PictureForRoomInput,
    PictureInput,
    Price,
    PriceInput,
    Room,
    RoomInput,
    RoomLabelOption,
    RoomLabelOptionInput,
    RoomShapePoint,
    RoomShapePointInput,
    RoomTransition,
    RoomTransitionInput,
    ServiceForRoom,
    ServiceForRoomInput,
    StepForRoom,
    StepForRoomInput
} from "generated/graphql";
import { prepareCustomService } from "Globals/dataPreparationUtils";
import { isNotNullOrUndefined, isNullOrUndefined } from "Globals/GenericValidators";
import { cleanNoteString } from "Pages/Admin/ProjectManagement/Dashboard/Breakdown/Notes/EditableNote";
import { RootState } from "./store";

type JobForReducer = { jobConfiguration?: JobConfiguration };

interface GenericSalesReducer extends JobForReducer {
    //Defined so that create slice can infer the type
    labelEditorDialogIndex: number;
    sqftEditorDialogIndex: number;
    servicesEditorDialogIndex: number;
    assignUnassignedRoomDialogIndex: number;
    selectedAreaIndex: number;
    originalJobConfiguration?: JobConfiguration;
    doesAreaIndexHaveRoomChanges: boolean[];
    doesAreaIndexHaveServiceChanges: boolean[];
    cost?: number;
    quotePrice: string;
    displayPrice: string;
    margin: string;
    lockPrice: boolean;

}

const newRoom: Room = {
    id: -1,
    jobConfigurationId: -1,
    areaId: -1,
    sqft: -1,
    lnft: -1,
    substrateId: -1,
    isCarpetVerticallyRun: true,
    notes: "",
    shape: [],
    svgPosition: { x: 0, y: 0 },
    labels: [],
    services: [],
    steps: [],
    pictures: [],
    beforePictures: [],
    afterPictures: [],
    carpetCuts: []
};

const newArea: Area = {
    id: -1,
    jobConfigurationId: -1,
    productTypeId: -1,
    notes: "",
    rooms: [],
    customServices: []
};

const initialState: GenericSalesReducer = {
    jobConfiguration: {
        id: -1,
        jobId: -1,
        optionNumber: -1,
        notes: "",
        areas: [],
        unassignedRooms: [],
        roomTransitions: [],
    },
    labelEditorDialogIndex: -1,
    sqftEditorDialogIndex: -1,
    servicesEditorDialogIndex: -1,
    assignUnassignedRoomDialogIndex: -1,
    selectedAreaIndex: -1,
    doesAreaIndexHaveRoomChanges: [],
    doesAreaIndexHaveServiceChanges: [],
    cost: undefined,
    quotePrice: "",
    displayPrice: "",
    margin: "",
    lockPrice: false,

};

export function convertConfigToInput(
    jobConfiguration: JobConfiguration,
    includePricing?: boolean
): JobConfigurationInput {
    var {
        id,
        jobId,
        optionNumber,
        notes,
        areas,
        unassignedRooms,
        roomTransitions,
        discounts,
        financing,
        price,
        drawingSVG
    } = jobConfiguration;

    const priceFields =
        includePricing && isNotNullOrUndefined(financing) && isNotNullOrUndefined(price)
            ? { financing: convertFinancingToInput(financing!), price: convertPriceToInput(price!) }
            : {};

    return {
        id,
        jobId,
        optionNumber,
        drawingSVG: drawingSVG,
        notes: cleanNoteString(notes),
        areas: areas.map(convertAreaToInput),
        roomTransitions: roomTransitions.map(convertRoomTransitionToInput),
        unassignedRooms: unassignedRooms.map(convertRoomToInput),
        discounts: convertDiscountToInput(discounts),
        ...priceFields,
    };
}

export function convertFinancingToInput(financing: Financing): FinancingInput {
    const { __typename, financingOption, ...rest } = financing;
    return { ...rest, financingOption: convertFinancingOptionToInput(financingOption) };
}

export function convertFinancingOptionToInput(
    financingOption?: Maybe<FinancingOption>
): Maybe<FinancingOptionInput> | undefined {
    if (isNullOrUndefined(financingOption)) return null;
    const { __typename, ...rest } = financingOption!;
    return rest;
}

export function convertPriceToInput(price: Price): PriceInput {
    const { __typename, ...rest } = price;
    return rest;
}

export function convertDiscountToInput(
    discount?: Maybe<DiscountsOnJob>
): Maybe<DiscountsOnJobInput> | undefined {
    if (isNullOrUndefined(discount)) return discount;
    else {
        const { __typename, discounts, availableDiscounts, ...rest } = discount!;

        return {
            ...rest,
            discounts: discounts.map((dis) => convertAppliedDiscount(dis)),
            availableDiscounts: [],
        };
    }
}

export function convertAppliedDiscount(discount: AppliedDiscount): AppliedDiscountInput {
    const { __typename, ...rest } = discount;

    return {
        ...rest,
    };
}

export function convertAreaToInput(area: Area): AreaInput {
    var { id, rooms, jobConfigurationId, productTypeId, notes, includedInQuote, styleId, colorId, preferredCarpetLength, oppositeCarpetLength, customServices } =
        area;

    return {
        id,
        jobConfigurationId,
        productTypeId,
        styleId,
        colorId,
        notes: cleanNoteString(notes),
        includedInQuote,
        preferredCarpetLength,
        oppositeCarpetLength,
        rooms: rooms.map(convertRoomToInput),
        customServices: customServices.map(prepareCustomService)
    };
}

export function convertRoomTransitionToInput(roomTransition: RoomTransition): RoomTransitionInput {
    var { __typename, ...fields } = roomTransition;

    return {
        ...fields,
    };
}

export function convertRoomToInput(room: Room): RoomInput {
    var {
        id,
        jobConfigurationId,
        areaId,
        sqft,
        lnft,
        substrateId,
        shape,
        svgPosition,
        notes,
        isCarpetVerticallyRun,
        labels,
        services,
        steps,
        pictures,
        beforePictures,
        afterPictures,
        carpetCuts,
        svg,
        detailedSvg
    } = room;

    return {
        id,
        jobConfigurationId,
        areaId,
        sqft,
        lnft,
        substrateId,
        svg,
        detailedSvg,
        isCarpetVerticallyRun,
        shape: shape.map(convertPointToInput),
        svgPosition: convertPointToInput(svgPosition),
        notes: cleanNoteString(notes),
        labels: labels.map(convertLabelToInput),
        services: services.map(convertServiceToInput),
        steps: steps.map(convertStepToInput),
        pictures: pictures.map(convertPictureForRoomToInput),
        beforePictures: beforePictures.map(convertPictureForRoomToInput),
        afterPictures: afterPictures.map(convertPictureForRoomToInput),
        carpetCuts: carpetCuts.map(convertCarpetCutToInput),
    };
}

function convertCarpetCutToInput(carpetCut: CarpetCut): CarpetCutInput {
    var { __typename, shape, pieces, ...fields } = carpetCut;

    return {
        ...fields,
        shape: shape.map(convertPointToInput),
        pieces: pieces.map(convertCarpetCutPiecesToInput),
    };
}

function convertCarpetCutPiecesToInput(carpetCut: CarpetCutPiece): CarpetCutPieceInput {
    var { __typename, roomShape, packerShape, ...fields } = carpetCut;

    return {
        ...fields,
        roomShape: roomShape.map(convertPointToInput),
        packerShape: packerShape.map(convertPointToInput),
    };
}

function convertPointToInput(point: RoomShapePoint): RoomShapePointInput {
    var { __typename, ...fields } = point;

    return { ...fields };
}

function convertLabelToInput(labelForRoom: LabelForRoom): LabelForRoomInput {
    var { __typename, label, ...fields } = labelForRoom;

    return { ...fields, label: convertLabelOptionToInput(label ?? undefined) };
}

function convertLabelOptionToInput(roomLabelOption?: RoomLabelOption): RoomLabelOptionInput {
    var { __typename, ...fields } = roomLabelOption ?? { id: -1, label: "", fullLabel: "" };
    return { ...fields };
}

function convertServiceToInput(serviceForRoom: ServiceForRoom): ServiceForRoomInput {
    var { __typename, ...fields } = serviceForRoom;
    return { ...fields };
}

function convertStepToInput(stepForRoom: StepForRoom): StepForRoomInput {
    var { __typename, ...fields } = stepForRoom;
    return { ...fields };
}

function convertPictureForRoomToInput(pictureForRoom: PictureForRoom): PictureForRoomInput {
    var { __typename, picture, ...fields } = pictureForRoom;

    const pic = picture ?? undefined;
    return { ...fields, picture: pic === undefined ? undefined : convertPictureToInput(pic) };
}

export function convertPictureToInput(pictureForRoom: Picture): PictureInput {
    var { __typename, ...fields } = pictureForRoom;

    return { ...fields };
}

//A slice is a collection of reducer logic and actions. It will be combined to form the store in ./store
export const genericSalesSlice = createSlice({
    name: "genericSales",
    initialState,
    reducers: {
        setGenericSalesReducerState(
            state: GenericSalesReducer,
            action: PayloadAction<JobConfiguration>
        ) {
            var jobConfiguration = action.payload;
            state.jobConfiguration = { ...jobConfiguration };
            state.originalJobConfiguration = { ...jobConfiguration };
            state.doesAreaIndexHaveRoomChanges = [...jobConfiguration.areas.map((a) => false)];
            state.doesAreaIndexHaveServiceChanges = [...jobConfiguration.areas.map((a) => false)];
        },
        setLabelsForRoom: {
            reducer(
                state: GenericSalesReducer,
                action: PayloadAction<{ labels: LabelForRoom[]; roomIndex: number }>
            ) {
                var { labels, roomIndex } = action.payload;

                const areaIndex = state.selectedAreaIndex;
                const roomCount = state.jobConfiguration!.areas[areaIndex].rooms.length;

                //Does Room and Area Exist
                if (roomIndex < roomCount) {
                    state.jobConfiguration!.areas[areaIndex].rooms[roomIndex].labels = labels;
                } else {
                    throw new Error("Attempting to update area that does not exist");
                }
            },
            prepare(roomIndex: number, labels: LabelForRoom[]) {
                return { payload: { labels, roomIndex } };
            },
        },
        createNewArea(state: GenericSalesReducer) {
            state.jobConfiguration!.areas = [...state.jobConfiguration!.areas, { ...newArea }];
            state.doesAreaIndexHaveRoomChanges = [...state.doesAreaIndexHaveRoomChanges, true];
            state.doesAreaIndexHaveServiceChanges = [
                ...state.doesAreaIndexHaveServiceChanges,
                true,
            ];
        },
        removeArea(state: GenericSalesReducer, action: PayloadAction<number>) {
            const trgIndex = action.payload;
            const { areas, ...fields } = state.jobConfiguration!;

            state.jobConfiguration = {
                ...fields,
                areas: areas.filter((a, index) => index !== trgIndex),
            };

            state.doesAreaIndexHaveRoomChanges = state.doesAreaIndexHaveRoomChanges.filter(
                (a, index) => index !== trgIndex
            );
            state.doesAreaIndexHaveServiceChanges = state.doesAreaIndexHaveServiceChanges.filter(
                (a, index) => index !== trgIndex
            );
        },
        updateArea: {
            reducer(
                state: GenericSalesReducer,
                action: PayloadAction<{ index: number; partialArea: Partial<Area> }>
            ) {
                var { index, partialArea } = action.payload;
                if (state.jobConfiguration?.areas[index] !== undefined) {
                    state.jobConfiguration!.areas[index] = {
                        ...state.jobConfiguration!.areas[index],
                        ...partialArea,
                    };
                }
            },
            prepare(index: number, partialArea: Partial<Area>) {
                return { payload: { index, partialArea } };
            },
        },
        createNewRoom: {
            reducer(state: GenericSalesReducer, action: PayloadAction<number>) {
                state.jobConfiguration!.areas[action.payload].rooms = [
                    ...state.jobConfiguration!.areas[action.payload].rooms,
                    { ...newRoom },
                ];

                state.doesAreaIndexHaveRoomChanges[action.payload] = true;
                state.doesAreaIndexHaveServiceChanges[action.payload] = true;
            },
            prepare(areaIndex: number) {
                return { payload: areaIndex };
            },
        },
        removeRoom: {
            reducer(
                state: GenericSalesReducer,
                action: PayloadAction<{ areaIndex: number; roomIndex: number }>
            ) {
                const { areaIndex, roomIndex } = action.payload;
                const rooms = state.jobConfiguration!.areas[areaIndex].rooms;
                const targetRoom = rooms[roomIndex];

                state.jobConfiguration!.unassignedRooms = [
                    ...state.jobConfiguration!.unassignedRooms,
                    targetRoom,
                ];
                state.jobConfiguration!.areas[areaIndex].rooms = rooms.filter(
                    (r, index) => index !== roomIndex
                );

                state.doesAreaIndexHaveRoomChanges[areaIndex] = true;
                state.doesAreaIndexHaveServiceChanges[areaIndex] = true;
            },
            prepare(areaIndex: number, roomIndex: number) {
                return { payload: { areaIndex, roomIndex } };
            },
        },
        updateRoom: {
            reducer(
                state: GenericSalesReducer,
                action: PayloadAction<{
                    areaIndex: number;
                    roomIndex: number;
                    partialRoom: Partial<Room>;
                }>
            ) {
                var { areaIndex, roomIndex, partialRoom } = action.payload;
                const room = state.jobConfiguration?.areas[areaIndex].rooms[roomIndex];
                if (room !== undefined) {
                    state.jobConfiguration!.areas[areaIndex].rooms[roomIndex] = {
                        ...room,
                        ...partialRoom,
                    };
                }

                if (partialRoom.services !== undefined) {
                    state.doesAreaIndexHaveServiceChanges[areaIndex] = true;
                }
            },
            prepare(areaIndex: number, roomIndex: number, partialRoom: Partial<Room>) {
                return { payload: { areaIndex, roomIndex, partialRoom } };
            },
        },
        removeAllUnassignedRooms(state: GenericSalesReducer) {
            state.jobConfiguration!.unassignedRooms = [];
        },
        removeUnassignedRoom: {
            reducer(
                state: GenericSalesReducer,
                action: PayloadAction<{ unassignedRoomIndex: number }>
            ) {
                const { unassignedRoomIndex } = action.payload;
                const rooms = state.jobConfiguration!.unassignedRooms;

                state.jobConfiguration!.unassignedRooms = rooms.filter(
                    (r, index) => index !== unassignedRoomIndex
                );
            },
            prepare(unassignedRoomIndex: number) {
                return { payload: { unassignedRoomIndex } };
            },
        },
        assignUnassignedRoom: {
            reducer(
                state: GenericSalesReducer,
                action: PayloadAction<{ unassignedRoomIndex: number; areaIndex: number }>
            ) {
                const { unassignedRoomIndex, areaIndex } = action.payload;
                const unassignedRoom = state.jobConfiguration!.unassignedRooms[unassignedRoomIndex];
                const unassignedRooms = state.jobConfiguration!.unassignedRooms;

                state.jobConfiguration!.areas[areaIndex].rooms = [
                    ...state.jobConfiguration!.areas[areaIndex].rooms,
                    unassignedRoom,
                ];
                state.jobConfiguration!.unassignedRooms = unassignedRooms.filter(
                    (r, index) => index !== unassignedRoomIndex
                );
            },
            prepare(unassignedRoomIndex: number, targetAreaIndex: number) {
                return { payload: { unassignedRoomIndex, areaIndex: targetAreaIndex } };
            },
        },
        openLabelForRoomDialog: {
            reducer(
                state: GenericSalesReducer,
                action: PayloadAction<{ roomIndex: number; areaIndex?: number }>
            ) {
                var { roomIndex, areaIndex } = action.payload;

                state.labelEditorDialogIndex = roomIndex;
                state.selectedAreaIndex = areaIndex ?? state.selectedAreaIndex;
            },
            prepare(roomIndex: number, areaIndex?: number) {
                return { payload: { roomIndex, areaIndex } };
            },
        },
        openRoomSqftDialog: {
            reducer(
                state: GenericSalesReducer,
                action: PayloadAction<{ roomIndex: number; areaIndex?: number }>
            ) {
                var { roomIndex, areaIndex } = action.payload;

                state.sqftEditorDialogIndex = roomIndex;
                state.selectedAreaIndex = areaIndex ?? state.selectedAreaIndex;
            },
            prepare(roomIndex: number, areaIndex?: number) {
                return { payload: { roomIndex, areaIndex } };
            },
        },
        openAssignUnassignedRoomDialog: {
            reducer(state: GenericSalesReducer, action: PayloadAction<number>) {
                state.assignUnassignedRoomDialogIndex = action.payload;
            },
            prepare(unassignedRoomIndex: number) {
                return { payload: unassignedRoomIndex };
            },
        },
        openServicesForRoomDialog: {
            reducer(
                state: GenericSalesReducer,
                action: PayloadAction<{ roomIndex: number; areaIndex?: number }>
            ) {
                var { roomIndex, areaIndex } = action.payload;

                state.servicesEditorDialogIndex = roomIndex;
                state.selectedAreaIndex = areaIndex ?? state.selectedAreaIndex;
            },
            prepare(roomIndex: number, areaIndex?: number) {
                return { payload: { roomIndex, areaIndex } };
            },
        },
        setChangeOrderAreaIndex(state: GenericSalesReducer, action: PayloadAction<number>) {
            const trgIndex = action.payload;

            state.selectedAreaIndex = trgIndex;
        },
        setQuotePrice(
            state: GenericSalesReducer,
            action: PayloadAction<{ price: string; cost?: number }>
        ) {
            const { price, cost } = action.payload;
            state.cost = cost ?? state.cost;
            state.quotePrice = price;

            if (!state.lockPrice) {
                state.displayPrice = state.quotePrice;
            }

            state.margin = recalculateMargin(state.displayPrice, state.cost);
        },
        setMargin(
            state: GenericSalesReducer,
            action: PayloadAction<{ margin: string; cost?: number }>
        ) {
            const { margin, cost } = action.payload;
            state.cost = cost ?? state.cost;
            state.margin = margin;

            const marginAsNumber = +state.margin;
            if (!isNaN(marginAsNumber) && state.cost !== undefined && state.cost > 0) {
                state.quotePrice = ((marginAsNumber / 100 + 1) * state.cost).toFixed(2);
                if (!state.lockPrice) {
                    state.displayPrice = state.quotePrice;
                }
            }
        },
        setLockPrice(state: GenericSalesReducer, action: PayloadAction<boolean>) {
            state.lockPrice = action.payload;
            if (!action.payload) {
                //Unlocking price, so display price should be updated to the quote price and margin recalculated
                state.displayPrice = state.quotePrice;
                state.margin = recalculateMargin(state.displayPrice, state.cost);
            }
            //When locking price, nothing should change. The price should freeze where it is at
        },
    },
});

function recalculateMargin(displayPrice: string, cost: number | undefined): string {
    const priceAsNumber = +displayPrice;
    if (!isNaN(priceAsNumber) && cost !== undefined && cost > 0) {
        const margin = (priceAsNumber / cost - 1) * 100;
        if (margin < 0) return "-";
        else return margin.toFixed(1);
    } else return "-";
}

export const {
    setGenericSalesReducerState,
    setLabelsForRoom,
    createNewArea,
    removeArea,
    updateArea,
    createNewRoom,
    removeRoom,
    updateRoom,
    assignUnassignedRoom,
    removeUnassignedRoom,
    removeAllUnassignedRooms,
    openAssignUnassignedRoomDialog,
    openLabelForRoomDialog,
    openRoomSqftDialog,
    openServicesForRoomDialog,
    setChangeOrderAreaIndex,
    setQuotePrice,
    setMargin,
    setLockPrice,
} = genericSalesSlice.actions; //Unpacks the actions created in the slice

export const UNASSINGED_AREA_INDEX = -2;
export const MAKE_NEW_AREA_INDEX = -3;

export const selectUnassignedRoomCount = (state: RootState) =>
    state.genericSales.jobConfiguration?.unassignedRooms.length ?? 0;
export const selectUnassignedRooms = (state: RootState) =>
    state.genericSales.jobConfiguration?.unassignedRooms ?? [];
export const selectUnassignedRoom = (roomIndex: number) => (state: RootState) =>
    state.genericSales.jobConfiguration?.unassignedRooms[roomIndex];
export const selectAreaCount = (state: RootState) =>
    state.genericSales.jobConfiguration?.areas.length ?? 0;
export const selectRoomCount = (areaIndex: number) => (state: RootState) =>
    state.genericSales.jobConfiguration?.areas[areaIndex].rooms.length ?? 0;
export const selectLabelsForRoom = (areaIndex: number, roomIndex: number) => (state: RootState) =>
    state.genericSales.jobConfiguration?.areas[areaIndex].rooms[roomIndex].labels ?? [];
export const selectRoomSqft = (areaIndex: number, roomIndex: number) => (state: RootState) =>
    state.genericSales.jobConfiguration?.areas[areaIndex].rooms[roomIndex].sqft;
export const selectRoomSubstrate = (areaIndex: number, roomIndex: number) => (state: RootState) =>
    state.genericSales.jobConfiguration?.areas[areaIndex].rooms[roomIndex].substrateId;
export const selectServicesForRoom = (areaIndex: number, roomIndex: number) => (state: RootState) =>
    state.genericSales.jobConfiguration?.areas[areaIndex].rooms[roomIndex].services ?? [];
export const selectRoom = (areaIndex: number, roomIndex: number) => (state: RootState) =>
    state.genericSales.jobConfiguration?.areas[areaIndex].rooms[roomIndex];
export const selectArea = (areaIndex: number) => (state: RootState) =>
    state.genericSales.jobConfiguration!.areas[areaIndex];

export const selectSelectedArea = (state: RootState) => selectArea(selectAreaIndex(state))(state);

export const selectDoesAreaHaveRoomChanges = (areaIndex: number) => (state: RootState) =>
    state.genericSales.doesAreaIndexHaveRoomChanges[areaIndex] ?? true;
export const selectDoesAreaHaveServiceChanges = (areaIndex: number) => (state: RootState) =>
    state.genericSales.doesAreaIndexHaveServiceChanges[areaIndex] ?? true;

export const selectLabelForRoomDialogLabels = (state: RootState) =>
    selectLabelsForRoom(
        state.genericSales.selectedAreaIndex,
        state.genericSales.labelEditorDialogIndex
    )(state);
export const selectLabelForRoomDialogOpen = (state: RootState) =>
    state.genericSales.labelEditorDialogIndex !== -1 && state.genericSales.selectedAreaIndex !== -1;

export const selectAllLabelForRoomLabels = (state: RootState) => [
    ...(state.genericSales.jobConfiguration?.areas ?? []).flatMap((area) =>
        area.rooms.flatMap((room) => room.labels)
    ),
    ...(state.genericSales.jobConfiguration?.unassignedRooms ?? []).flatMap((room) => room.labels),
];

export const selectRoomSqftDialogSqft = (state: RootState) =>
    selectRoomSqft(
        state.genericSales.selectedAreaIndex,
        state.genericSales.sqftEditorDialogIndex
    )(state);
export const selectRoomSqftDialogOpen = (state: RootState) =>
    state.genericSales.sqftEditorDialogIndex !== -1 && state.genericSales.selectedAreaIndex !== -1;

export const selectUnassignedRoomDialogIndex = (state: RootState) =>
    state.genericSales.assignUnassignedRoomDialogIndex;
export const selectUnassignedRoomDialogOpen = (state: RootState) =>
    state.genericSales.assignUnassignedRoomDialogIndex !== -1;

export const selectServicesForRoomDialogServices = (state: RootState) =>
    selectServicesForRoom(
        state.genericSales.selectedAreaIndex,
        state.genericSales.servicesEditorDialogIndex
    )(state);
export const selectServicesForRoomDialogSubstrate = (state: RootState) =>
    selectRoomSubstrate(
        state.genericSales.selectedAreaIndex,
        state.genericSales.servicesEditorDialogIndex
    )(state);
export const selectServicesForRoomDialogProductTypeId = (state: RootState) =>
    selectArea(state.genericSales.selectedAreaIndex)(state).productTypeId;
export const selectServicesForRoomDialogSqft = (state: RootState) =>
    selectRoomSqft(
        state.genericSales.selectedAreaIndex,
        state.genericSales.servicesEditorDialogIndex
    )(state);
export const selectServicesForRoomDialogLabels = (state: RootState) =>
    selectLabelsForRoom(
        state.genericSales.selectedAreaIndex,
        state.genericSales.servicesEditorDialogIndex
    )(state);
export const selectServicesForRoomDialogOpen = (state: RootState) =>
    state.genericSales.servicesEditorDialogIndex !== -1 &&
    state.genericSales.selectedAreaIndex !== -1;

export const selectChangeOrderEditorMode = (state: RootState) => "Job Editor";
export const selectAreaIndex = (state: RootState) => state.genericSales.selectedAreaIndex;

export const selectAreas = (state: RootState) => state.genericSales.jobConfiguration?.areas;

export const selectOriginalJobConfig = (state: RootState) =>
    state.genericSales.originalJobConfiguration;

export const selectDisplayPrice = (state: RootState) => state.genericSales.displayPrice;
export const selectDisplayMargin = (state: RootState) => state.genericSales.margin;
export const selectLockDisplayPrice = (state: RootState) => state.genericSales.lockPrice;

export default genericSalesSlice.reducer;