import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import FlatSection from "FlatComponents/Layout/FlatSection";
import { Customer } from "generated/graphql";
import { formatAppointmentDateStringAbbreviated } from "Globals/DateAndTimeHelpers";
import { formatAddress, formatPhoneNumber } from "Globals/StringFormatting";
import { Breakdowns, BreakdownTableProps, makeLaborBreakdownRows, makeMaterialBreakdownRows } from "../../Breakdown/BreakdownTableUtils";
import woStyles from './printableWorkOrder.module.css';

export interface WorkOrderProps {
    sahNum: string;
    customer: Customer;
    salesRepNames: string[],
    projectCoordinatorName: string;
    contractorName: string;
    dates: string[];
    hasFinancing: boolean;
    remainingCod: number;
    areaBreakdowns: Breakdowns;
    notes: ReactJSXElement[];
}

export default function PrintableWorkOrder({
    sahNum,
    contractorName,
    projectCoordinatorName,
    dates,
    customer,
    salesRepNames,
    areaBreakdowns,
    hasFinancing,
    remainingCod,
    notes
}: WorkOrderProps) {
    function makeHeaderAdornment() {
        let dateRangeStr = formatAppointmentDateStringAbbreviated(dates);
        let numDaysStr = '';
        if (dates.length === 1) {
            numDaysStr = "(1 day)";
        } else {
            const dateStrSplit = dateRangeStr.split(' ');
            dateRangeStr = dateStrSplit.slice(0, -2).join(" ");
            numDaysStr = `${dateStrSplit[dateStrSplit.length - 2].replace('[', '(')} ${dateStrSplit[dateStrSplit.length - 1].replace(']', ')')}`;
        }

        return (
            <span id={woStyles.woHeaderContent} className="flex-row flex-gap-xsm flat-font-md" style={{fontWeight: "normal"}}>
                <p className="margin-none">{contractorName}</p>
                <p className="margin-none">[{sahNum}]</p>
                <p className="margin-none">{dateRangeStr}</p>
                <p className="margin-none" style={{color: "var(--flat-gray-4)"}}>{numDaysStr}</p>
            </span>
        )
    }
    
    return (
        <FlatSection
            header="Work Order"
            endAdornment={makeHeaderAdornment()}
            removePadding 
        >           
            <div className="padding-vertical-none padding-side-sm">
                <div id={woStyles.printableWoCustDetails} className="margin-top-xsm margin-bottom-xsm fill-width">
                    <div id={woStyles.woCustLabel} className="flex-row justify-content-flex-start">
                        <p className="margin-none">Customer Name</p>
                    </div>
                    <div id={woStyles.woCustName} className="flex-row justify-content-flex-start">
                        <p className="margin-none">{customer.firstName} {customer.lastName}</p>
                    </div>

                    <div id={woStyles.woAddressLabel} className="flex-row justify-content-center">
                        <p className="margin-none">Address</p>
                    </div>
                    <div id={woStyles.woAddress} className="flex-row justify-content-flex-start">
                        <p className="margin-none">{formatAddress(customer.primaryAddress)}</p>
                    </div>

                    <div id={woStyles.woSalesRepLabel} className="flex-row margin-left-md">
                        <p className="margin-none">Sales Rep{salesRepNames.length > 1 && 's'}</p>
                    </div>
                    <div id={woStyles.woSalesRep} className="flex-row justify-content-flex-end">
                        <p className="margin-none">{salesRepNames.join(', ')}</p>
                    </div>

                    <div id={woStyles.woPhoneLabel} className="flex-row justify-content-flex-start">
                        <p className="margin-none">Phone Number</p>
                    </div>
                    <div id={woStyles.woPhoneNum} className="flex-row justify-content-flex-start">
                        <p className="margin-none">{formatPhoneNumber(customer.primaryPhone)}</p>
                    </div>

                    <div id={woStyles.woEmailLabel} className="flex-row justify-content-center">
                        <p className="margin-vertical-none" style={{marginRight: '13pt'}}>Email</p>
                    </div>
                    <div id={woStyles.woEmail} className="flex-row justify-content-flex-start">
                        <p className="margin-none">{customer.email}</p> 
                    </div>

                    <div id={woStyles.woProjectMgrLabel} className="flex-row margin-left-md">
                        <p className="margin-none">Project Coordinator</p>
                    </div>
                    <div id={woStyles.woProjectMgr} className="flex-row justify-content-flex-end">
                        <p className="margin-none">{projectCoordinatorName}</p>
                    </div>

                    <div id={woStyles.woCodLabel} className="flex-row margin-left-md">
                        <p className="margin-none bold-text flat-font-md">{hasFinancing ? "Financing" : "COD"}</p>
                    </div>
                    <div id={woStyles.woCod}>
                        <p className=" bold-text flat-font-md margin-none flex-row justify-content-flex-end">${remainingCod.toFixed(2)}</p>
                    </div>
                </div>

                <div className="thick-horizontal-bar" style={{backgroundColor: "var(--flat-gray-5)"}} />

                <PrintableWorkOrderMaterialTable areaBreakdowns={areaBreakdowns}/>
                <PrintableWorkOrderLaborTable areaBreakdowns={areaBreakdowns}/>

                <div className="fill-width margin-top-sm">
                    <div className={woStyles.printableWoTableTitle}>Notes</div>
                    <div className="padding-xsm">
                        {notes}
                    </div>
                </div>
            </div>     
        </FlatSection>
    )
}

function PrintableWorkOrderMaterialTable({areaBreakdowns}: BreakdownTableProps) {
    return (
        <div className="margin-top-sm fill-width">
            <div className={woStyles.printableWoTableTitle}>Material</div>

            <table className={woStyles.printableWoTable}>
                <thead className="solid-vertical-border">
                    <tr>
                        <th align="center">Line</th>
                        <th align="center">Product</th>
                        <th align="center">Sq. Ft</th>
                        <th align="center">Style</th>
                        <th align="center">Color</th>
                        <th align="center">Area To Cover</th>
                    </tr>
                </thead>
                <tbody>
                    {makeMaterialBreakdownRows(areaBreakdowns, true).map((row, i) => (
                        <tr key={`material-${i}`}>
                            <td>{row.line}</td>
                            <td>{row.productType}</td>
                            <td>{row.amount.toFixed(0)}</td>
                            <td>{row.style}</td>
                            <td>{row.color}</td>
                            <td>{row.area}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

function PrintableWorkOrderLaborTable({areaBreakdowns}: BreakdownTableProps) {
    return (
        <div className="margin-top-sm fill-width">
            <div className={woStyles.printableWoTableTitle}>Labor</div>

            <table className={woStyles.printableWoTable}>
                <thead className="solid-vertical-border">
                    <tr>
                        <th align="center">Line</th>
                        <th align="center">Service</th>
                        <th align="center">QTY</th>
                        <th align="center">Description</th>
                        <th align="center">Area To Cover</th>
                    </tr>
                </thead>
                <tbody>
                    {makeLaborBreakdownRows(areaBreakdowns, true).map((row, i) => (
                        <tr key={`labor-${i}`}>
                            <td>{row.line}</td>
                            <td>{row.type}</td>
                            <td>{(row.amount === 0) ? "" : row.amount.toFixed(0)} {row.amountUnit}</td>
                            <td>{row.description}</td>
                            <td>{row.area}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}
