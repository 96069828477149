import { Maybe, ProductIdentifiersWithCostsInput } from "generated/graphql";
import { KeyedChangedNumber, toRowId } from "Redux/productCostsReducer";

type ProductCosts = { bulkCost?: number, singleCost?: number, oneTimeCost?: number }
type FEIdentifiedProductCosts = { [key: string]: { bulkCost?: number, singleCost?: number, oneTimeCost?: number } }

export function fToB_ProductCosts(bulkChanges: KeyedChangedNumber, singleChanges: KeyedChangedNumber, costScaler: number = 1) {
    const costs: FEIdentifiedProductCosts = {}
    Object.keys(bulkChanges).forEach(key => {
        var { value: cost, changed } = bulkChanges[key]
        if (changed) costs[key] = { ...costs[key], bulkCost: cost }
    })
    Object.keys(singleChanges).forEach(key => {
        var { value: cost, changed } = singleChanges[key]
        if (changed) costs[key] = { ...costs[key], singleCost: cost }
    })

    return Object.keys(costs).map(key => fToB_ProductCost(key, costs[key], costScaler))
}

function scaleCost(cost: number | undefined, scaler: number){
    if(cost === undefined) return undefined
    else return cost * scaler
}

export function fToB_ProductCost(
    identifier: string,
    costs: ProductCosts,
    costScaler: number): ProductIdentifiersWithCostsInput {

    return {
        ...fToB_Idenfiers(identifier),
        bulkCost: scaleCost(costs.bulkCost, costScaler),
        singleCost: scaleCost(costs.singleCost, costScaler),
        oneTimeCost: scaleCost(costs.oneTimeCost, costScaler),        
    }
}

export function fToB_Idenfiers(identifier: string): Pick<ProductIdentifiersWithCostsInput, "sourceId" | "styleId"> {
    const split = identifier.split("-")
    const first = +split[0]
    const second = (split.length === 2) ? +split[1] : undefined

    return {
        styleId: first,
        sourceId: second
    }
}

export function bToF_Identifiers(styleId: number, sourceId?: number) {
    return toRowId(styleId, sourceId)
}

interface f_costs {
    bulkCostPerSqft: number,
    singleCostPerSqft: number,
    oneTimeCostPerSqft: number | undefined
}

type MaybeNumber = Maybe<number> | undefined

export function bToF_Costs(
    bulkCost: MaybeNumber,
    singleCost: MaybeNumber,
    oneTimeCost: MaybeNumber): f_costs {

    var bulkCostPerSqft : number = bulkCost ?? 0.00
    var singleCostPerSqft : number = singleCost ?? 0.00
    var oneTimeCostPerSqft : number | undefined = oneTimeCost ?? undefined

    return {
        bulkCostPerSqft,
        singleCostPerSqft,
        oneTimeCostPerSqft
    }
}