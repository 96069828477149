import { useGetAppointmentWorkOrderQuery, useGetJobInvoiceDataQuery, useGetProjectCoordinatorFromConfigurationQuery } from "generated/graphql";
import { isNotNullOrUndefined, isNullOrUndefined } from "Globals/GenericValidators";
import { useTwoNumericIdParams } from "Globals/Hooks";
import { useEffect, useState } from "react";
import PrintableWorkOrder from "./PrintableWorkOrder";
import woStyles from './printableWorkOrder.module.css';
import { getNotes } from "./WorkOrdersPage";

export default function PrintWorkOrderPage() {
    const {id1: jobConfigurationId, id2: appointmentId } = useTwoNumericIdParams();
    const { data: projectCoordinatorData } = useGetProjectCoordinatorFromConfigurationQuery(
        {variables: 
            {jobConfigurationId: jobConfigurationId ?? 0},
        skip: isNullOrUndefined(jobConfigurationId)}
    );
    const projectCoordinator = projectCoordinatorData?.projectCoordinatorFromConfiguration;
    
    const {data: jobData} = useGetJobInvoiceDataQuery({
        variables: {jobConfigurationId: jobConfigurationId ?? 0},
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-only",
        skip: (jobConfigurationId ?? 0) < 1
    });
    const customer = jobData?.jobConfigurationHeader.customer;
    const sahNum = jobData?.jobConfigurationHeader.appointmentNumber;
    const salesReps = jobData?.jobConfigurationHeader.assignedSalespeople.map(s => `${s.firstName}, ${s.lastName[0]}.`);
    
    const [workOrderError, setWorkOrderError] = useState(false);
    // TODO: need to decouple this from the ServiceForRoomBreakdown type because it contains information like LaborPricePerUnit, which is completely irrelevant here
    const {data: woData } = useGetAppointmentWorkOrderQuery({
        variables: {installationAppointmentId: appointmentId, jobConfigurationId: jobConfigurationId},
        skip: isNullOrUndefined(appointmentId) || isNullOrUndefined(jobConfigurationId),
        onError: () => setWorkOrderError(true)
    });
    let workOrder = woData?.appointmentWorkOrder;
    let remainingCod = workOrder?.remainingCod;


    useEffect(() => {
        if (isNotNullOrUndefined(customer) && isNotNullOrUndefined(remainingCod) && isNotNullOrUndefined(sahNum) && isNotNullOrUndefined(salesReps) && isNotNullOrUndefined(workOrder)) {
            window.print();
        }
    }, [customer, remainingCod, sahNum, salesReps, workOrder])

    return (<>
    {workOrderError ? <p className="error-text">Could not load work order</p> : (
        (isNotNullOrUndefined(customer) && isNotNullOrUndefined(remainingCod) && isNotNullOrUndefined(sahNum) && isNotNullOrUndefined(salesReps) && isNotNullOrUndefined(workOrder)) && (
            <div className="flex-row justify-content-center" id={woStyles.woPrintContainer}>
                <PrintableWorkOrder
                    sahNum={sahNum ?? ''}
                    customer={customer!}
                        salesRepNames={salesReps!}
                        projectCoordinatorName={`${projectCoordinator?.firstName ?? ""}, ${projectCoordinator?.lastName[0] ?? ""}.`}
                    contractorName={`${workOrder!.contractor.firstName} ${workOrder!.contractor.lastName}`}
                    dates={workOrder!.dates}
                    hasFinancing={workOrder!.hasFinancing}
                    remainingCod={remainingCod!}
                    areaBreakdowns={workOrder!.areaBreakdowns}
                    notes={getNotes(workOrder!)}
                />
            </div>
    ))}</>)
}