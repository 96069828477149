import FlatSection, { FlatSubSection } from "FlatComponents/Layout/FlatSection";
import { useGetFullyScaledJobConfigurationQuery, useGetJobConfigurationQuery } from "generated/graphql";
import JobSvgViewer from "Pages/Home/ContractorHome/InstallJob/JobSvgViewer";
import { useAppSelector } from "Redux/hooks";
import { getNameOfArea } from "Redux/JobReducerDataStructures/AreaType";
import { selectJobConfigurationId, selectJobContractId } from "Redux/jobTrackerReducer";
import { RoomSvgProps, DetailedRoomSvg } from "../SellSheet/QuoteDetailsEditor/RoomSvg";
import { CARPET_PRODUCT_ID } from "Globals/globalConstants";
import FlatButton from "FlatComponents/Button/FlatButton";
import { useAppHistory } from "Globals/routingHooks";
import { buildAppendedId } from "Globals/Hooks";
import { printMeasurePath, seamPlannerPath } from "Globals/PathStrings";
import { BreakdownTableProps, makeMaterialBreakdownRows } from "./Breakdown/BreakdownTableUtils";
import { formatAsIntOrNDecimals } from "../../../../Globals/StringFormatting";


const productIDS: number[] = [4];

export default function ContractDrawingViewer({ areaBreakdowns }: BreakdownTableProps) {

    const jobConfigurationId = useAppSelector(selectJobConfigurationId);
    const jobContractId = useAppSelector(selectJobContractId);


    const { data } = useGetFullyScaledJobConfigurationQuery({
        variables: { jobConfigurationId },
        skip: jobConfigurationId < 1,
    });
    const breakdownRows = makeMaterialBreakdownRows(areaBreakdowns);

    const history = useAppHistory();

    const areas = data?.fullyScaledJobConfiguration.areas ?? [];




    const hasCarpet = areas.some((area) => area.productTypeId === CARPET_PRODUCT_ID);
    //console.log(breakdownRows.filter(product => product.line !== undefined));
    const totalAmount = breakdownRows.filter(product => product.line !== undefined);
    const linearFeet = breakdownRows[1].amount;
    const unit = breakdownRows.length > 0 ? breakdownRows[0].amountUnit : '';
    const linearFeetUnit = breakdownRows.length > 0 ? breakdownRows[1].amountUnit : '';
    const productTypeId = areaBreakdowns[0].product.productTypeId;


    return (
        <div className="margin-bottom-sm">
            <div className="padding-top-sm margin-bottom-sm">
                <FlatSection

                    removePadding
                    header={
                        <>
                            <div style={{ marginTop: "2em", display: "flex", gap: "1.5em" }}>
                                <p>Full Drawing</p>
                                <span className="additional-data" style={{ display: "flex", gap: "1.5em" }}>
                                    {totalAmount.map((product, index) => (
                                        <span key={product.line}>Area {index + 1} : {formatAsIntOrNDecimals(product.amount)}{unit}</span>
                                    ))}


                                    <p>  {!productIDS.includes(productTypeId) ? `Perimeter: ${formatAsIntOrNDecimals(linearFeet)}${linearFeetUnit}` : ""}</p>
                                </span>
                            </div>
                        </>
                    }
                    endAdornment={
                        <>
                            <FlatButton
                                onClick={() =>
                                    history.push(
                                        printMeasurePath + buildAppendedId(jobContractId)
                                    )
                                }
                            >
                                Print
                            </FlatButton>
                            {hasCarpet && (
                                <FlatButton
                                    color="secondary"
                                    onClick={() =>
                                        history.push(
                                            seamPlannerPath + buildAppendedId(jobConfigurationId)
                                        )
                                    }
                                >
                                    Seam Planner
                                </FlatButton>
                            )}
                        </>
                    }
                    collapsible
                    className="fill-width"
                >
                    <JobSvgViewer jobConfigurationId={jobConfigurationId} />
                </FlatSection>
            </div>
            <FlatSection
                header="Individual Rooms"
                collapsible
                defaultClosed
                className="fill-width"
            >
                <div className="grid-50-50 flex-gap-sm padding-top-sm">
                    {areas.flatMap((a, idx) =>
                        a.rooms.map((r) => (
                            <RoomSvgContainer
                                key={r.id}
                                room={r}
                                areaIndex={idx}
                            />
                        ))
                    )}
                </div>
            </FlatSection>
        </div>
    );
}

export function RoomSvgContainer({ room, areaIndex }: RoomSvgProps) {
    return (
        <FlatSubSection header={`${getNameOfArea(room.labels)} - ${room.sqft} Sqft`} >
            <div className="flex-row align-items-center justify-content-center">
                <DetailedRoomSvg
                    room={room}
                    areaIndex={areaIndex}
                />
            </div>
        </FlatSubSection>
    );
}