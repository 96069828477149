import { Button, Container, MenuItem, Modal, Select } from "@material-ui/core";
import clsx from "clsx";
import NavbarPage from "Components/Page";
import Section from "Components/PageLayout/Section";
import { namedOperations, useAddNewColorMutation, useAddNewProductSourceMutation, useAddNewProductSpecMutation } from "generated/graphql";
import { isEmptyString } from "Globals/GenericValidators";
import { useEffect } from "react";
import { resetAddProduct, selectIsNewColorMode, selectIsNewSpecMode, selectIsOneTimeCost, selectIsStyleUnselected, selectIsVendorFilledOut, selectNSEMode, setColor, setIsOneTimeCost, setSpec } from "Redux/addProductReducer";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { store } from "Redux/store";
import { v_fToBProductSpecification, v_fToBVendorSourcedProduct } from "./Conversions";
import CreateNewStyle from "./CreateNewStyle";
import VariantSpecEditor from "./ProductVariantEditor/VariantSpecEditor";
import ColorEditor from "./SimpleEditors/ColorEditor";
import ProductBulkCostEditor from "./SimpleEditors/ProductBulkCostEditor";
import ProductOneTimeCostEditor from "./SimpleEditors/ProductOneTimeCostEditor";
import ProductSingleCostEditor from "./SimpleEditors/ProductSingleCostEditor";
import StyleEditor from "./SimpleEditors/StyleEditor";
import TypeEditor from "./SimpleEditors/TypeEditor";
import UploadMode from "./SimpleEditors/UploadModeEditor";
import VendorColorEditor from "./SimpleEditors/VendorColorEditor";
import VendorEditor from "./SimpleEditors/VendorEditor";
import VendorOrderSizeEditor from "./SimpleEditors/VendorOrderSizeEditor";
import VendorStyleEditor from "./SimpleEditors/VendorStyleEditor";
import { useHistory, useRouteMatch } from "react-router";

type AddButtonScenario = "None" | "Add New Product" | "Add New Supplier" | "Add New Variation"

export default function AddHardProductPage() {
    const editorMode = useAppSelector(selectNSEMode)
    const dispatch = useAppDispatch()
    const history = useHistory()
    const {path }=useRouteMatch()

    useEffect(
        () => {dispatch(resetAddProduct())},
        [dispatch]
    );

    const [addNewProduct, { loading: addNewLoading }] = useAddNewColorMutation({
        onCompleted(data) {
            dispatch(setColor({ id: data.addNewColor, value: "" }))
        },
        onError(err) {
            window.alert(err.message);
        },
        refetchQueries: [
            namedOperations.Query.GetProductColorOptions
        ],
        awaitRefetchQueries: true
    });

    const [addNewSpec, { loading: addSpecLoading }] = useAddNewProductSpecMutation({
        onCompleted(data) {
            dispatch(setSpec({ id: data.addNewProductSpec, value: "" }))
        },
        onError(err) {
            window.alert(err.message);
        },
        refetchQueries: [
            namedOperations.Query.GetProductSpecNames
        ],
        awaitRefetchQueries: true
    });

    const [addNewSupplier, { loading: addSourceLoading }] = useAddNewProductSourceMutation({
        onCompleted(data) {
            window.alert("New supplier added");
        },
        onError(err) {
            window.alert(err.message);
        }
    });

    //OR together all loading variables from the various mutations
    const loading = addNewLoading || addSpecLoading || addSourceLoading

    function submitProduct(scenario: AddButtonScenario) {
        if (loading) return;
        try {
            switch (scenario) {
                case "Add New Product":
                    submitNewProduct();
                    return;
                case "Add New Supplier":
                    submitAddNewSupplier();
                    return;
                case "Add New Variation":
                    submitAddNewVariation();
                    return;
                default:
                    return;
            }
        }
        catch (e) {
            window.alert(e)
        }
    }

    function submitAddNewVariation() {
        const addProductData = store.getState().addProduct

        const colorId = addProductData.color.id
        const specName = addProductData.spec.value
        const specValues = addProductData.colorVariantSpecValues
        const specDetails = v_fToBProductSpecification(specValues.sqft, specValues.productDetails, specValues.installationMethods)
        const vendor = v_fToBVendorSourcedProduct(
            addProductData.vendorId,
            addProductData.vendorStyle,
            addProductData.vendorColor,
            addProductData.vendorOrderSize,
            addProductData.singleCost,
            addProductData.bulkCost,
            addProductData.isOneTimeCost ? addProductData.oneTimeCost : undefined)

        if (isEmptyString(specName)) throw new Error("Spec name is blank")
        if (colorId < 1) throw new Error("Select a color")

        addNewSpec({
            variables: {
                colorId,
                specName,
                specDetails,
                vendor
            }
        })
    }

    function submitAddNewSupplier() {
        const addProductData = store.getState().addProduct

        const productSpecId = addProductData.spec.id
        const vendor = v_fToBVendorSourcedProduct(
            addProductData.vendorId,
            addProductData.vendorStyle,
            addProductData.vendorColor,
            addProductData.vendorOrderSize,
            addProductData.singleCost,
            addProductData.bulkCost,
            addProductData.isOneTimeCost ? addProductData.oneTimeCost : undefined)

        if (productSpecId < 1) throw new Error("Select a product specification")

        addNewSupplier({
            variables: {
                productSpecId,
                vendor
            }
        })
    }

    function submitNewProduct() {
        const addProductData = store.getState().addProduct

        const styleId = addProductData.styleId
        const colorName = addProductData.color.value
        const specName = "default" //When adding a new color the first variant should just be default
        const specValues = addProductData.colorVariantSpecValues
        const specDetails = v_fToBProductSpecification(specValues.sqft, specValues.productDetails, specValues.installationMethods)
        const vendor = v_fToBVendorSourcedProduct(
            addProductData.vendorId,
            addProductData.vendorStyle,
            addProductData.vendorColor,
            addProductData.vendorOrderSize,
            addProductData.singleCost,
            addProductData.bulkCost,
            addProductData.isOneTimeCost ? addProductData.oneTimeCost : undefined)

        if (isEmptyString(colorName)) throw new Error("Color name is blank")
        if (styleId < 1) throw new Error("Select a style")

        addNewProduct({
            variables: {
                styleId,
                colorName,
                specName,
                specDetails,
                vendor
            }
        })
    }

    return (
        <NavbarPage title="Add Hard Surface Product">
            <div className="fill-width flex-column padding-side-lg">
                <Section title="Product Source">
                    <div className="fill-width flex-row flex-gap-md">
                        <TypeEditor />
                        <VendorEditor />
                        <VendorStyleEditor />
                        <StyleEditor />
                        <VendorOrderSizeEditor />
                    </div>
                </Section>
                <Section title="Product Labeling">
                    <div className="flex-column fill-width">
                        <div className="flex-row fill-width flex-gap-md">
                            <UploadMode value={false} setValue={(v) => {alert("Not implemented")}} />
                            <VendorColorEditor />
                            <ColorEditor />
                            <div className="flex-column-reverse">
                                <AddProductButton onClick={submitProduct} />
                            </div>
                        </div>
                        <div className="flex-row fill-width" style={{ paddingTop: "1rem", alignItems: "flex-start" }}>
                            <CostEditors />
                        </div>
                    </div>
                </Section>
                <Section title="Product Specification">
                    <VariantSpecEditor />
                </Section>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => history.push(path.replace("add-hard-surface", "product-pricing"))}
                >
                    Back to Product Pricing
                    </Button>
                </div>
            </div>

            <Modal open={editorMode !== "None"}>
                <div className="fill-height flex-row flex-centered" style={{ justifyContent: "space-around" }}>
                    <Container maxWidth="md" className="modal-content padding-md">
                        <CreateNewStyle isForHardSurface/>
                    </Container>
                </div>
            </Modal>
        </NavbarPage>
    )
}

function AddProductButton({ onClick }: { onClick: (scenario: AddButtonScenario) => void }) {
    const isNewColor = useAppSelector(selectIsNewColorMode)
    const isVendorFilledOut = useAppSelector(selectIsVendorFilledOut)
    const isNewSpec = useAppSelector(selectIsNewSpecMode)
    const styleIsUnselected = useAppSelector(selectIsStyleUnselected)


    function determineScenario(): AddButtonScenario {
        if (!styleIsUnselected && isVendorFilledOut) {
            if (isNewColor) return "Add New Product";
            else if (isNewSpec) return "Add New Variation";
            else return "Add New Supplier";
        }
        
        return "None";
    }

    const scenario: AddButtonScenario = determineScenario();

    return (
        <Button
            style={{ minWidth: "8rem" }}
            className={clsx({ 'visibility-hidden': scenario === "None" })}
            variant="contained"
            onClick={() => onClick(scenario)}
        >
            {scenario}
        </Button>
    )
}

function CostEditors() {
    const isOneTime = useAppSelector(selectIsOneTimeCost)
    const dispatch = useAppDispatch()

    return (
        <div className="flex-row flex-gap-md">
            <div className="flex-column w-10r">
                <label htmlFor="cost-mode">Cost Type:</label>
                <Select value={+isOneTime} onChange={e => dispatch(setIsOneTimeCost(Boolean(e.target.value)))} className="w-10r">
                    <MenuItem value={+true}>One Time Cost</MenuItem>
                    <MenuItem value={+false}>Reoccuring Cost</MenuItem>
                </Select>
            </div>
            
            {isOneTime ? (<>
                <ProductOneTimeCostEditor />
            </>) : (
                <span className="flex-row flex-gap-md">
                    <div className="w-10r">
                        <ProductSingleCostEditor />
                    </div>

                    <span className="w-10r">
                        <ProductBulkCostEditor />
                    </span>
                </span>
            )}
        </div>
    )
}