import { GetInstallationAppointmentsQuery } from "generated/graphql";
import { allTrue, isNotNullOrUndefined } from "Globals/GenericValidators";
import { getNameOfArea } from "Redux/JobReducerDataStructures/AreaType";
import { Breakdowns } from "../../BreakdownTableUtils";
import {
    InstallationCapabilities, ProductTypeInstallationCandidates, ProductWithRooms,
    Room, ScheduledInstallationSummary,
    StagedAppointmentSchedulings
} from "./installationSchedulingTypes";

export function buildInstallationSummaries(
    installationAppointments: GetInstallationAppointmentsQuery["installationAppointments"]
): ScheduledInstallationSummary[] {
    let summaries: ScheduledInstallationSummary[] = [];
    installationAppointments.forEach((a) => {
        let appt = a.appointment;
        let rfi = a.rooms;
        let products: ProductWithRooms[] = [];

        rfi.forEach((r) => {
            let room: Room = {
                id: r.id,
                name: getNameOfArea(r.labels),
                sqft: r.sqft!,
            };
            let pwrEntry: ProductWithRooms | undefined = products.find((p) => p.productTypeId === r.product.productTypeId);
            if (pwrEntry) {
                // product entry exists, just need to add this room to it
                pwrEntry!.rooms.push(room);
                pwrEntry!.installAmt += room.sqft;
            } else {
                let pwr = {
                    // must add this product to the list
                    productTypeId: r.product.productTypeId,
                    productType: r.product.productType,
                    productStyle: r.product.productStyle,
                    installAmt: r.sqft!,
                    rooms: [room],
                };

                products.push(pwr);
            }
        });

        let summary: ScheduledInstallationSummary = {
            apptId: appt.id,
            contractorId: appt.contractorId,
            contractorFname: appt.contractorFirstName!,
            contractorLname: appt.contractorLastName!,
            dates: appt.dates,
            cod: appt.cod,
            productsForAppointment: products,
            notes: appt.notes ?? "",
        };

        summaries.push(summary);
    });

    return summaries;
}


export function pTypesFromStaged(staged: StagedAppointmentSchedulings) {
    let pTypeIds: Set<number> = new Set<number>();
    Object.keys(staged).forEach((aId) => {
        pTypeIds.add(staged[+aId].productTypeId);
    });

    return pTypeIds;
}

export function roomIdsFromStaged(staged: StagedAppointmentSchedulings) {
    let roomIds: number[] = [];
    Object.keys(staged).forEach((areaId) => {
        roomIds = [...roomIds, ...staged[+areaId].roomIds];
    });

    return roomIds;
}

// determines whether a room still needs to be scheduled
function roomNeedsScheduling(
    roomId: number,
    installationSummaries: ScheduledInstallationSummary[]
): boolean {
    let needsSched = true;

    installationSummaries.forEach((summary) => {
        summary.productsForAppointment.forEach((pfa) => {
            pfa.rooms.forEach((room) => {
                if (room.id === roomId) {
                    needsSched = false;
                }
            });
        });
    });

    return needsSched;
}

// builds the data structures used to display product types which still require scheduling
export function buildInstallationCandidates(
    areaBreakdowns: Breakdowns,
    installationSummaries: ScheduledInstallationSummary[]
): ProductTypeInstallationCandidates {
    let potentialCandidates: ProductTypeInstallationCandidates = {};

    areaBreakdowns.forEach((ab) => {
        const thisPtypeId = ab.product.productTypeId;
        if (!Object.keys(potentialCandidates).map(ptId => +ptId).includes(thisPtypeId)) {
            // initialize this grouping
            potentialCandidates[thisPtypeId] = {
                productType: ab.product.productType,
                totalSqft: 0,
                totalPendingSqft: 0,
                rooms: []
            }
        }

        ab.rooms?.forEach(r => {
            if (roomNeedsScheduling(r.id, installationSummaries)) {
                potentialCandidates[thisPtypeId].totalPendingSqft += r.sqft;
                potentialCandidates[thisPtypeId].rooms[r.id] = {
                    areaId: ab.areaId,
                    roomName: getNameOfArea(r.labels),
                    sqft: r.sqft,
                    price: r.price!.total
                };
            }

            potentialCandidates[thisPtypeId].totalSqft += r.sqft;
        });
    });

    // filter out any product types which don't have any rooms pending scheduling
    let candidates: ProductTypeInstallationCandidates = {};
    Object.keys(potentialCandidates).forEach(ptId => {
        if (Object.keys(potentialCandidates[+ptId].rooms).length !== 0) {
            candidates[+ptId] = potentialCandidates[+ptId];
        }
    });
    
    return candidates;
}

// ensure contractor is able to install all selected product types selected
export function contractorIsCapable(
    capabilities: InstallationCapabilities,
    selectedPtypeIds: number[]
) {
    let capabilityProductTypes = capabilities.map((c) => c.productTypeId);
    // checks that ALL selected product types are available in the supplied list of capabilities
    return allTrue(selectedPtypeIds.map((pId) => capabilityProductTypes.includes(pId)));
}

// filters contractors that can install all of the staged installations
// export function filterCapableContractors(contractors: Contractors, setCapableContractors: (contractors: Contractors) => void, selectedContractorId: number, setSelectedContractorId: (id: number) => void, staged: StagedAppointmentSchedulings) {
//     let pTypeIds = pTypesFromStaged(staged);
//     if (pTypeIds.size === 0) {
//         // can't select a contractor until at least 1 installation has been staged
//         setCapableContractors([]);
//         setSelectedContractorId(0);
//     } else {
//         // check that the contractor can install all selected material types
//         // spead turns the set into a list
//         let filtered = contractors.filter(c => contractorIsCapable(c.installationCapabilities, [...pTypeIds])) ?? [];
//         // clears the selected contractor if the contractor is not capable of installating the new set of selected product types
//         if (!filtered.find(c => c.contractorId === selectedContractorId)) {setSelectedContractorId(0);}
//         setCapableContractors(filtered);
//     }
// }

export function stagedForSchedulingValid(
    stagedForScheduling: StagedAppointmentSchedulings
): boolean {
    if (isNotNullOrUndefined(stagedForScheduling)) {
        let stagedAreaIds = Object.keys(stagedForScheduling);
        if (stagedAreaIds.length !== 0) {
            stagedAreaIds.forEach((areaId) => {
                let stagedRoomIds = stagedForScheduling[+areaId].roomIds;
                if (stagedRoomIds.length === 0) {
                    return false;
                }
            });
        } else {
            return false;
        }
    } else {
        return false;
    }

    return true;
}
