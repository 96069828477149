import clsx from "clsx";
import React from "react";

interface FlatContainerProps extends React.HTMLProps<HTMLDivElement> {
    removePadding?: boolean;
    isInnerContainer?: boolean;
    darkBackground?: boolean;
}

export default function FlatContainer({ className, removePadding, isInnerContainer, darkBackground, ...props }: FlatContainerProps) {
    return (
        <div
         
            className={clsx("flat-container flat-outer-container flat-font-sm", className, {
                "flat-outer-container-padding": removePadding !== true,
                "flat-container-box-shadow" : isInnerContainer !== true,
                "flat-dark-bkg" : darkBackground === true
            })}
            {...props}
        />
    );
}
